import React from "react";
import classNames from "classnames";
import Login from "./components/Login";
import LoginByMac from "./components/LoginByMac";

import styles from "./login.module.scss";

export default function LoginSection() {
  return (
    <section className={classNames(styles["login-page"],styles['observable-section'])} id="login">
      <div className={styles["login-page__layer"]}></div>
      <div className={styles["holder"]}>
        <div className={styles["login-page-wrapper"]}>
          <div className={styles["login-page__image-block"]}></div>
          <div className={styles["login-page__form"]}>
            <LoginByMac />
            <Login />
            <div className={styles["login-section-bottom"]}>
              <p className={styles["login-page__form__title"]}>
                Login to your account
              </p>
              <p className={styles["login-page__form__bottom-text"]}>
                Your account will give you ability to manage your TV devices
                playlists, playlist goops and VOD content.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["login-page__bottom"]}></div>
    </section>
  );
}
