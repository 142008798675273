import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../../api/requests";
import MessageComponent from "../../../../components/MessageComponent";
import countries from "../../../../assets/json/countries.json";
import ButtonComponent from "../../../../components/ButtonComponent";

import styles from "../reseller.module.scss";
import axios from "axios";

export default function ResellerForm() {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [checkboxes, setCheckboxes] = useState({
    all: false,
    privacy: false,
    terms: false,
  });

  const [partnerType, setPartnerType] = useState({
    is_reseller: true,
    is_referral: false,
  });

  const [country, setCountry] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    phone: Yup.string().required("Phone is required"),
    country: Yup.string().required("Country is required"),
    company: Yup.string().required("Company is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password is too short."),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        country: "",
        company: "",
        address: "",
      },
      onSubmit: (values) => {
        register();
        setCheckboxes({
          all: false,
          privacy: false,
          terms: false,
        });
        setPartnerType({
          is_reseller: true,
          is_referral: false,
        });
      },
      validationSchema,
    });

  const onMouseLeave = () => {
    const id = setTimeout(() => {
      setOpen(false);
    }, 500);
    setId(id);
  };

  const onMouseOver = () => {
    clearTimeout(id);
  };

  const onCheck = (e) => {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: e.target.checked,
    });
  };

  const register = () => {
    const body = {
      ...values,
      phone: country.dialCode + values.phone,
      is_resseler: partnerType.is_reseller,
      is_refferal: partnerType.is_referral,
      is_privacy_accepted: checkboxes.privacy,
      is_usage_accepted: checkboxes.terms,
    };

    if (selectedCode) {
      body.phone = selectedCode.dialCode + values.phone;
    }

    console.log(country, "country");
    console.log(body, "body");

    REQUESTS.RESELLER_REGISTRATION(body).then((data) => {
      if (data.error) {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      } else {
        window.location.replace("https://iredtv.net/reseller/spa/#/login");
        resetForm();
      }
    });
  };

  const getCountry = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((data) => {
        const country = countries.find((item) =>
          item.dialCode.includes(data.data.country_calling_code)
        );
        setCountry(country);
      })
      .catch((err) => setCountry(null));
  };

  useEffect(() => {
    getCountry();
  }, [country]);

  useEffect(() => {
    if (checkboxes.all && !checkboxes.privacy && !checkboxes.terms) {
      setCheckboxes(() => ({
        all: true,
        privacy: true,
        terms: true,
      }));
    } else if (!checkboxes.all && checkboxes.privacy && checkboxes.terms) {
      setCheckboxes(() => ({
        ...checkboxes,
        privacy: false,
        terms: false,
      }));
    } else if (checkboxes.all && !checkboxes.privacy && checkboxes.terms) {
      setCheckboxes(() => ({
        ...checkboxes,
        all: true,
        privacy: true,
        terms: true,
      }));
    }
  }, [checkboxes.all]);

  useEffect(() => {
    if (checkboxes.all && !checkboxes.privacy && checkboxes.terms) {
      setCheckboxes(() => ({
        ...checkboxes,
        all: false,
      }));
    } else if (checkboxes.all && checkboxes.privacy && !checkboxes.terms) {
      setCheckboxes(() => ({
        ...checkboxes,
        all: false,
      }));
    } else if (!checkboxes.all && checkboxes.privacy && checkboxes.terms) {
      setCheckboxes(() => ({
        ...checkboxes,
        all: true,
      }));
    }
  }, [checkboxes.terms, checkboxes.privacy]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={styles["become-reseller-form-content"]}>
        <h1 className={styles["become-reseller-form-content-title"]}>
          BECOME A PARTNER
        </h1>
        <form
          onSubmit={handleSubmit}
          className={styles["become-reseller-form"]}
        >
          <input
            name="company"
            type="text"
            placeholder="Enter your company name"
            className={styles["become-reseller-form__inp"]}
            value={values.company}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.company && touched.company ? errors.company : null}
          />
          <input
            name="name"
            type="text"
            placeholder="Enter your name"
            className={styles["become-reseller-form__inp"]}
            value={values.name}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.name && touched.name ? errors.name : null}
          />
          <input
            name="surname"
            type="text"
            placeholder="Enter your surname"
            className={styles["become-reseller-form__inp"]}
            value={values.surname}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.surname && touched.surname ? errors.surname : null}
          />
          <select
            className={styles["become-reseller-form__inp"]}
            name="country"
            onChange={handleChange}
          >
            <option value="" disabled selected hidden>
              Select your country
            </option>
            {countries.map((item) => (
              <option value={item.name} key={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          <MessageComponent
            message={errors.country && touched.country ? errors.country : null}
          />
          <input
            name="address"
            type="text"
            placeholder="Enter your address"
            className={styles["become-reseller-form__inp"]}
            value={values.address}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.address && touched.address ? errors.address : null}
          />
          <input
            name="email"
            type="email"
            placeholder="Enter your email"
            className={styles["become-reseller-form__inp"]}
            value={values.email}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.email && touched.email ? errors.email : null}
          />
          <div className={styles["phone-input-container"]}>
            <div className={styles["phone-codes"]}>
              {selectedCode ? (
                <div
                  className={styles["phone-codes-dropdown-item-selected"]}
                  onClick={() => setOpen(!open)}
                >
                  <img src={selectedCode?.flag} width={20} alt="" />
                  <span>{selectedCode.dialCode}</span>
                </div>
              ) : country ? (
                <div
                  className={styles["phone-codes-dropdown-item-selected"]}
                  onClick={() => setOpen(!open)}
                >
                  <img src={country?.flag} width={20} alt="" />
                  <span>{country?.dialCode}</span>
                </div>
              ) : (
                <div
                  className={styles["phone-icon"]}
                  onClick={() => setOpen(!open)}
                  onMouseLeave={onMouseLeave}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.617"
                    height="20.617"
                    viewBox="0 0 20.617 20.617"
                    style={{ marginRight: "10px" }}
                  >
                    <path
                      id="Icon_awesome-phone-alt"
                      data-name="Icon awesome-phone-alt"
                      d="M20.029,14.569l-4.51-1.933a.966.966,0,0,0-1.128.278l-2,2.44A14.926,14.926,0,0,1,5.259,8.219l2.44-2a.964.964,0,0,0,.278-1.128L6.044.584A.973.973,0,0,0,4.937.025L.749.991A.966.966,0,0,0,0,1.933,18.682,18.682,0,0,0,18.684,20.617a.966.966,0,0,0,.942-.749l.966-4.188a.978.978,0,0,0-.564-1.111Z"
                      transform="translate(0 0)"
                      fill="#353535"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                  >
                    <path
                      id="Polygon_2"
                      data-name="Polygon 2"
                      d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                      transform="translate(12 10) rotate(180)"
                      fill="#353535"
                    />
                  </svg>
                </div>
              )}
              <div
                className={
                  open
                    ? styles["open-phone-codes-dropdown"]
                    : styles["phone-codes-dropdown"]
                }
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              >
                {countries.map((item) => (
                  <div
                    key={item.isoCode}
                    className={styles["phone-codes-dropdown-item"]}
                    onClick={() => {
                      setOpen(false);
                      setSelectedCode(item);
                    }}
                  >
                    <img src={item.flag} width={20} alt="" />
                    <span>{item.dialCode}</span>
                  </div>
                ))}
              </div>
            </div>

            <input
              name="phone"
              type="number"
              placeholder="Enter your phone number"
              className={styles["become-reseller-form__inp"]}
              value={values.phone}
              onChange={handleChange}
            />
          </div>
          <MessageComponent
            message={errors.phone && touched.phone ? errors.phone : null}
          />
          <input
            name="password"
            type="password"
            placeholder="Enter your password"
            className={styles["become-reseller-form__inp"]}
            value={values.password}
            onChange={handleChange}
          />
          <MessageComponent
            message={
              errors.password && touched.password ? errors.password : null
            }
          />
          <div className={styles["bottom-section"]}>
            <div className={styles["partner-type"]}>
              <h6 className={styles["partner-type__title"]}>
                Choose partner type
              </h6>
              <label htmlFor="reseller" className={styles["reseller"]}>
                <input
                  type="checkbox"
                  id="reseller"
                  className={styles["checkbox"]}
                  name="reseller"
                  checked={partnerType.is_reseller}
                  onChange={(e) =>
                    setPartnerType({
                      ...partnerType,
                      is_reseller: e.target.checked,
                    })
                  }
                />
                Reseller
              </label>
              <label htmlFor="referal" className={styles["referal"]}>
                <input
                  type="checkbox"
                  id="referal"
                  className={styles["checkbox"]}
                  checked={partnerType.is_referral}
                  onChange={(e) =>
                    setPartnerType({
                      ...partnerType,
                      is_referral: e.target.checked,
                    })
                  }
                />
                Referral
              </label>
            </div>
            <div className={styles["divider"]}></div>
            <div className={styles["checkboxes-group"]}>
              <label htmlFor="all" className={styles["all"]}>
                <input
                  type="checkbox"
                  id="all"
                  className={styles["checkbox"]}
                  name="all"
                  checked={checkboxes.all}
                  onChange={onCheck}
                />
                Accept privacy <br /> policy and usage
              </label>
              <label htmlFor="privacy-policy" className={styles["privacy"]}>
                <input
                  type="checkbox"
                  id="privacy-policy"
                  className={styles["checkbox"]}
                  name="privacy"
                  checked={checkboxes.privacy}
                  onChange={onCheck}
                />
                <Link to="/privacy">Privacy policy</Link>
              </label>
              <label htmlFor="terms-of-usag" className={styles["usage"]}>
                <input
                  type="checkbox"
                  id="terms-of-usage"
                  className={styles["checkbox"]}
                  name="terms"
                  checked={checkboxes.terms}
                  onChange={onCheck}
                />
                <Link to="/termsofuse">Terms of usage</Link>
              </label>
            </div>
          </div>
          <ButtonComponent
            text="Register"
            style={{
              width: 222,
              float: "right",
              background: checkboxes.all ? "#8E8C8C" : "#8E8C8C80",
            }}
            type="submit"
            disabled={!checkboxes.all}
          />
        </form>
      </div>
    </>
  );
}
