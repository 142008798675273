import React from "react";
import styles from './style.module.scss';

export default function PaymentError() {
  return (
    <div className={styles.err_container}>
      <svg
        width="304"
        height="299"
        viewBox="0 0 304 299"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M153.892 299C231.456 299 294.335 236.122 294.335 158.557C294.335 80.9926 231.456 18.1141 153.892 18.1141C76.3272 18.1141 13.4487 80.9926 13.4487 158.557C13.4487 236.122 76.3272 299 153.892 299Z"
          fill="url(#paint0_linear_240_143)"
        />
        <path
          d="M229.66 50.7946H74.0786C64.381 50.7946 56.5195 58.6561 56.5195 68.3536H247.219C247.219 58.6561 239.357 50.7946 229.66 50.7946Z"
          fill="url(#paint1_linear_240_143)"
        />
        <path
          d="M56.5195 67.8738H247.219V213.113C247.219 219.571 244.654 225.764 240.087 230.33C235.521 234.896 229.328 237.461 222.871 237.461H80.8674C74.4099 237.461 68.2169 234.896 63.6508 230.33C59.0847 225.764 56.5195 219.571 56.5195 213.113V67.8738Z"
          fill="url(#paint2_linear_240_143)"
        />
        <path
          d="M214.439 86.2189H89.3615C86.7566 86.2189 84.645 88.3282 84.645 90.9302C84.645 93.5322 86.7566 95.6415 89.3615 95.6415H214.439C217.044 95.6415 219.155 93.5322 219.155 90.9302C219.155 88.3282 217.044 86.2189 214.439 86.2189Z"
          fill="url(#paint3_linear_240_143)"
        />
        <path
          d="M214.439 126.252H89.3615C86.7566 126.252 84.645 128.362 84.645 130.964C84.645 133.566 86.7566 135.675 89.3615 135.675H214.439C217.044 135.675 219.155 133.566 219.155 130.964C219.155 128.362 217.044 126.252 214.439 126.252Z"
          fill="url(#paint4_linear_240_143)"
        />
        <path
          d="M214.439 166.285H89.3615C86.7566 166.285 84.645 168.395 84.645 170.997C84.645 173.599 86.7566 175.708 89.3615 175.708H214.439C217.044 175.708 219.155 173.599 219.155 170.997C219.155 168.395 217.044 166.285 214.439 166.285Z"
          fill="url(#paint5_linear_240_143)"
        />
        <path
          d="M214.439 206.319H89.3615C86.7566 206.319 84.645 208.428 84.645 211.03C84.645 213.632 86.7566 215.741 89.3615 215.741H214.439C217.044 215.741 219.155 213.632 219.155 211.03C219.155 208.428 217.044 206.319 214.439 206.319Z"
          fill="url(#paint6_linear_240_143)"
        />
        <g filter="url(#filter0_d_240_143)">
          <path
            d="M88.5145 157.699H21.9127C16.5621 157.699 12.2246 162.036 12.2246 167.387V262.583C12.2246 267.934 16.5621 272.271 21.9127 272.271H88.5145C93.8651 272.271 98.2026 267.934 98.2026 262.583V167.387C98.2026 162.036 93.8651 157.699 88.5145 157.699Z"
            fill="url(#paint7_linear_240_143)"
          />
        </g>
        <g filter="url(#filter1_d_240_143)">
          <path
            d="M278.108 9H204.544C201.116 9 198.337 11.7789 198.337 15.2069V49.7839C198.337 53.2119 201.116 55.9908 204.544 55.9908H278.108C281.536 55.9908 284.315 53.2119 284.315 49.7839V15.2069C284.315 11.7789 281.536 9 278.108 9Z"
            fill="url(#paint8_linear_240_143)"
          />
        </g>
        <path
          d="M81.0823 205.323H29.3444C27.6023 205.323 26.1899 206.735 26.1899 208.477V208.487C26.1899 210.229 27.6023 211.642 29.3444 211.642H81.0823C82.8245 211.642 84.2368 210.229 84.2368 208.487V208.477C84.2368 206.735 82.8245 205.323 81.0823 205.323Z"
          fill="url(#paint9_linear_240_143)"
        />
        <path
          d="M81.1116 249.787H29.8183C28.0911 249.787 26.6909 251.156 26.6909 252.844V252.853C26.6909 254.541 28.0911 255.91 29.8183 255.91H81.1116C82.8388 255.91 84.2389 254.541 84.2389 252.853V252.844C84.2389 251.156 82.8388 249.787 81.1116 249.787Z"
          fill="url(#paint10_linear_240_143)"
        />
        <path
          d="M81.1115 226.523H29.8183C28.0911 226.523 26.6909 227.892 26.6909 229.58V229.589C26.6909 231.277 28.0911 232.646 29.8183 232.646H81.1115C82.8388 232.646 84.2389 231.277 84.2389 229.589V229.58C84.2389 227.892 82.8388 226.523 81.1115 226.523Z"
          fill="url(#paint11_linear_240_143)"
        />
        <path
          d="M48.2613 172.399H33.1421C29.7931 172.399 27.0781 175.114 27.0781 178.463V182.659C27.0781 186.008 29.7931 188.723 33.1421 188.723H48.2613C51.6103 188.723 54.3252 186.008 54.3252 182.659V178.463C54.3252 175.114 51.6103 172.399 48.2613 172.399Z"
          fill="url(#paint12_linear_240_143)"
        />
        <path
          d="M216.493 39.6262C220.434 39.6262 223.629 36.4314 223.629 32.4903C223.629 28.5493 220.434 25.3544 216.493 25.3544C212.552 25.3544 209.357 28.5493 209.357 32.4903C209.357 36.4314 212.552 39.6262 216.493 39.6262Z"
          fill="#BEBBBB"
        />
        <path
          d="M241.994 39.6262C245.935 39.6262 249.13 36.4314 249.13 32.4903C249.13 28.5493 245.935 25.3544 241.994 25.3544C238.053 25.3544 234.858 28.5493 234.858 32.4903C234.858 36.4314 238.053 39.6262 241.994 39.6262Z"
          fill="#BEBBBB"
        />
        <path
          d="M267.496 39.6262C271.437 39.6262 274.632 36.4314 274.632 32.4903C274.632 28.5493 271.437 25.3544 267.496 25.3544C263.555 25.3544 260.36 28.5493 260.36 32.4903C260.36 36.4314 263.555 39.6262 267.496 39.6262Z"
          fill="#BEBBBB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M267.247 176.848C267.247 218.531 233.457 252.322 191.774 252.322C150.091 252.322 116.301 218.531 116.301 176.848C116.301 135.166 150.091 101.375 191.774 101.375C233.457 101.375 267.247 135.166 267.247 176.848ZM255.67 176.848C255.67 212.137 227.063 240.745 191.774 240.745C156.485 240.745 127.877 212.137 127.877 176.848C127.877 141.559 156.485 112.952 191.774 112.952C227.063 112.952 255.67 141.559 255.67 176.848Z"
          fill="url(#paint13_linear_240_143)"
        />
        <g filter="url(#filter2_b_240_143)">
          <path
            d="M191.774 240.745C227.063 240.745 255.671 212.137 255.671 176.848C255.671 141.559 227.063 112.952 191.774 112.952C156.485 112.952 127.878 141.559 127.878 176.848C127.878 212.137 156.485 240.745 191.774 240.745Z"
            fill="url(#paint14_linear_240_143)"
            fill-opacity="0.2"
          />
        </g>
        <path
          d="M207.128 176.848L225.402 158.534C227.114 156.722 228.052 154.314 228.016 151.822C227.981 149.329 226.975 146.949 225.213 145.186C223.45 143.424 221.069 142.418 218.577 142.382C216.085 142.347 213.677 143.285 211.865 144.997L193.55 163.311L175.277 144.997C174.397 144.066 173.34 143.322 172.168 142.807C170.996 142.292 169.732 142.017 168.452 141.999C167.172 141.981 165.901 142.219 164.715 142.701C163.528 143.183 162.451 143.897 161.545 144.802C160.64 145.708 159.925 146.786 159.444 147.972C158.962 149.158 158.724 150.429 158.742 151.709C158.76 152.989 159.035 154.253 159.549 155.425C160.064 156.597 160.809 157.654 161.74 158.534L180.044 176.848L161.74 195.163C160.809 196.042 160.064 197.099 159.549 198.271C159.035 199.444 158.76 200.707 158.742 201.987C158.724 203.268 158.962 204.538 159.444 205.725C159.925 206.911 160.64 207.989 161.545 208.894C162.451 209.799 163.528 210.514 164.715 210.996C165.901 211.477 167.172 211.716 168.452 211.698C169.732 211.68 170.996 211.405 172.168 210.89C173.34 210.375 174.397 209.63 175.277 208.7L193.591 190.385L211.906 208.7C213.735 210.311 216.109 211.163 218.545 211.084C220.981 211.006 223.295 210.001 225.016 208.275C226.737 206.549 227.735 204.231 227.807 201.795C227.878 199.359 227.018 196.987 225.402 195.163L207.128 176.848Z"
          fill="url(#paint15_linear_240_143)"
        />
        <g filter="url(#filter3_f_240_143)">
          <ellipse
            cx="193.887"
            cy="212.14"
            rx="44.9184"
            ry="6.63568"
            fill="#EC5575"
            fill-opacity="0.56"
          />
        </g>
        <path
          d="M142.433 254.318C142.95 252.174 146 252.174 146.518 254.318L149.176 265.333C149.361 266.099 149.96 266.697 150.726 266.882L161.74 269.541C163.884 270.059 163.884 273.108 161.74 273.626L150.726 276.285C149.96 276.469 149.361 277.068 149.176 277.834L146.518 288.848C146 290.992 142.95 290.992 142.433 288.848L139.774 277.834C139.589 277.068 138.991 276.469 138.225 276.285L127.21 273.626C125.066 273.108 125.066 270.059 127.21 269.541L138.225 266.882C138.991 266.697 139.589 266.099 139.774 265.333L142.433 254.318Z"
          fill="url(#paint16_linear_240_143)"
        />
        <path
          d="M40.8054 19.2283C41.3229 17.0843 44.3725 17.0843 44.8901 19.2283L47.5489 30.2427C47.7339 31.0089 48.3321 31.6071 49.0983 31.792L60.1127 34.4509C62.2567 34.9684 62.2567 38.0181 60.1127 38.5356L49.0983 41.1944C48.3321 41.3794 47.7339 41.9776 47.5489 42.7438L44.8901 53.7582C44.3725 55.9022 41.3229 55.9022 40.8054 53.7582L38.1465 42.7438C37.9616 41.9776 37.3634 41.3794 36.5972 41.1944L25.5828 38.5356C23.4388 38.0181 23.4388 34.9684 25.5828 34.4509L36.5972 31.792C37.3634 31.6071 37.9616 31.0089 38.1465 30.2427L40.8054 19.2283Z"
          fill="url(#paint17_linear_240_143)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M148.292 22.5585C150.66 22.5585 152.58 20.6389 152.58 18.2709C152.58 15.9029 150.66 13.9833 148.292 13.9833C145.924 13.9833 144.005 15.9029 144.005 18.2709C144.005 20.6389 145.924 22.5585 148.292 22.5585ZM148.292 25.7742C152.436 25.7742 155.796 22.4149 155.796 18.2709C155.796 14.1269 152.436 10.7676 148.292 10.7676C144.148 10.7676 140.789 14.1269 140.789 18.2709C140.789 22.4149 144.148 25.7742 148.292 25.7742Z"
          fill="url(#paint18_linear_240_143)"
        />
        <path
          d="M281.448 139.962C281.115 138.656 282.32 137.48 283.618 137.844L295.652 141.226C296.95 141.591 297.366 143.223 296.402 144.165L287.456 152.896C286.491 153.837 284.869 153.382 284.536 152.076L281.448 139.962Z"
          fill="url(#paint19_linear_240_143)"
        />
        <path
          d="M30.8987 122.046C32.2052 121.714 33.3804 122.921 33.014 124.219L29.6166 136.249C29.2503 137.546 27.6172 137.96 26.677 136.994L17.9573 128.037C17.0172 127.071 17.4751 125.45 18.7815 125.118L30.8987 122.046Z"
          fill="url(#paint20_linear_240_143)"
        />
        <path
          d="M235.378 270.201C236.685 269.87 237.86 271.077 237.494 272.374L234.096 284.405C233.73 285.702 232.097 286.116 231.157 285.15L222.437 276.193C221.497 275.227 221.955 273.606 223.261 273.274L235.378 270.201Z"
          fill="url(#paint21_linear_240_143)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M291.55 85.0042C293.918 85.0042 295.838 83.0846 295.838 80.7166C295.838 78.3487 293.918 76.429 291.55 76.429C289.182 76.429 287.263 78.3487 287.263 80.7166C287.263 83.0846 289.182 85.0042 291.55 85.0042ZM291.55 88.22C295.694 88.22 299.054 84.8606 299.054 80.7166C299.054 76.5727 295.694 73.2133 291.55 73.2133C287.406 73.2133 284.047 76.5727 284.047 80.7166C284.047 84.8606 287.406 88.22 291.55 88.22Z"
          fill="url(#paint22_linear_240_143)"
        />
        <defs>
          <filter
            id="filter0_d_240_143"
            x="0.224609"
            y="149.699"
            width="115.978"
            height="144.573"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3" dy="7" />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.587031 0 0 0 0 0.628324 0 0 0 0 0.6375 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_240_143"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_240_143"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_240_143"
            x="191.337"
            y="0"
            width="111.978"
            height="72.9908"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="6" dy="4" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.515937 0 0 0 0 0.526761 0 0 0 0 0.529167 0 0 0 0.09 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_240_143"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_240_143"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_b_240_143"
            x="111.203"
            y="96.2767"
            width="161.143"
            height="161.143"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.33757" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_240_143"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_240_143"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_f_240_143"
            x="128.125"
            y="184.66"
            width="131.525"
            height="54.9592"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="10.422"
              result="effect1_foregroundBlur_240_143"
            />
          </filter>
          <linearGradient
            id="paint0_linear_240_143"
            x1="152.809"
            y1="-27.6175"
            x2="155.686"
            y2="465.597"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="#F0EDED" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_240_143"
            x1="56.3036"
            y1="54.847"
            x2="247.314"
            y2="68.3157"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BEBABA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_240_143"
            x1="50.7937"
            y1="195.044"
            x2="326.29"
            y2="199.941"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F0EDED" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_240_143"
            x1="78.956"
            y1="95.253"
            x2="227.112"
            y2="95.2529"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BBB8B8" />
            <stop offset="1" stop-color="#EBE2E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_240_143"
            x1="78.956"
            y1="135.286"
            x2="227.112"
            y2="135.286"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BBB8B8" />
            <stop offset="1" stop-color="#EBE2E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_240_143"
            x1="78.956"
            y1="175.32"
            x2="227.112"
            y2="175.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BBB8B8" />
            <stop offset="1" stop-color="#EBE2E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_240_143"
            x1="78.956"
            y1="215.353"
            x2="227.112"
            y2="215.353"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BBB8B8" />
            <stop offset="1" stop-color="#EBE2E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_240_143"
            x1="9.64306"
            y1="243.614"
            x2="174.434"
            y2="257.149"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#E0D9D9" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_240_143"
            x1="195.756"
            y1="44.2374"
            x2="319.9"
            y2="47.8285"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F0EDED" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_240_143"
            x1="90.9733"
            y1="211.83"
            x2="21.181"
            y2="211.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F2E9E9" />
            <stop offset="1" stop-color="#B5B3B3" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_240_143"
            x1="90.9176"
            y1="256.092"
            x2="21.7251"
            y2="256.092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EAE2E2" />
            <stop offset="1" stop-color="#C5C1C1" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_240_143"
            x1="90.9176"
            y1="232.828"
            x2="21.7251"
            y2="232.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EBE3E3" />
            <stop offset="1" stop-color="#C1BDBD" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_240_143"
            x1="73.219"
            y1="188.566"
            x2="23.6297"
            y2="188.566"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.243799" stop-color="#EBE2E2" />
            <stop offset="1" stop-color="#BBB8B8" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_240_143"
            x1="116.13"
            y1="136.211"
            x2="268.068"
            y2="137.198"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D5CECE" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_240_143"
            x1="191.774"
            y1="112.952"
            x2="256.161"
            y2="280.028"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F3F1F1" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_240_143"
            x1="159.072"
            y1="146.687"
            x2="228.455"
            y2="211.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF698D" />
            <stop offset="1" stop-color="#FF3868" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_240_143"
            x1="118.691"
            y1="257.732"
            x2="170.481"
            y2="258.068"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_240_143"
            x1="17.0638"
            y1="22.6418"
            x2="68.8531"
            y2="22.9781"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_240_143"
            x1="140.772"
            y1="14.2309"
            x2="155.877"
            y2="14.329"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D5CECE" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_240_143"
            x1="276.466"
            y1="148.068"
            x2="292.006"
            y2="133.097"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_240_143"
            x1="22.7992"
            y1="117.053"
            x2="37.7509"
            y2="132.613"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_240_143"
            x1="227.279"
            y1="265.209"
            x2="242.23"
            y2="280.769"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_240_143"
            x1="284.03"
            y1="76.6766"
            x2="299.135"
            y2="76.7747"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D6CFCF" />
            <stop offset="1" stop-color="#BDB9B9" />
          </linearGradient>
        </defs>
      </svg>
      <div className={styles['text']}>Sorry, something went wrong</div>
    </div>
  );
}
