import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MessageComponent from "../../../../components/MessageComponent";
import REQUESTS from "../../../../api/requests";
import PATHS from "../../../../config/paths";

import styles from "../login.module.scss";

export default function Login() {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password is too short."),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: (values) => {
        loginByEmail();
      },
      validationSchema,
    });

  const validate = () => {
    REQUESTS.VALIDATE().then((data) => {
      if (data.error) {
        navigate("/");
      } else {
        navigate("devices");
        resetForm();
      }
    });
  };

  const loginByEmail = () => {
    REQUESTS.LOGIN_BY_EMAIL(values).then((data) => {
      if (data.error) {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        localStorage.setItem("token", data.message);
        localStorage.setItem("login_by",'email')
        validate();
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={styles["login-page__form-second"]} id="login">
        <p className={styles["login-page__form__title"]}>
          Login by mail and password
        </p>

        <form onSubmit={handleSubmit} className={styles["login-form-content"]}>
          <input
            name="email"
            type="email"
            placeholder="Enter your email address"
            onChange={handleChange}
            value={values.email}
            className={styles["login-page_input"]}
          />
          <MessageComponent
            message={errors.email && touched.email ? errors.email : null}
          />
          <input
            name="password"
            type="password"
            placeholder="Enter your password"
            onChange={handleChange}
            value={values.password}
            className={styles["login-page_input"]}
          />
          <MessageComponent
            message={
              errors.password && touched.password ? errors.password : null
            }
          />
          <div className={styles["form-bottom"]}>
            <Link to={PATHS.RESET_PASSWORD} >Forgot your password</Link>
            <button className={styles["login-page-btn"]} type="submit">
              Log in
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
