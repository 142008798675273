import React, { useState, useEffect } from "react";

import REQUESTS from "../../api/requests";
import ICONS from "../../config/icons";
import IMAGES from "../../config/images";

import AddDeviceModal from "./components/AddDeviceModal";
import ButtonComponent from "../../components/ButtonComponent";
import Device from "./components/Device";

import styles from "./devices.module.scss";
import { useNavigate } from "react-router-dom";

export default function DevicesPage() {
  const [devices, setDevices] = useState([]);
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const navigate = useNavigate();
  const getDevices = () => {
    const type = localStorage.getItem("login_by");
    if (type === "email") {
      REQUESTS.DEVICES.GET().then((res) => {
        if (!res.error) {
          setDevices(res.message);
        }
      });
    } else {
      REQUESTS.GET_DEVICE().then((data) => {
        if (!data.error) {
          setDevices([data.message]);
        }
      });
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div>
      <div className={styles["header-wrapper"]}>
        <div className={styles["holder"]}>
          <div className={styles["header"]}>
            <div className={styles["logo-wrapper"]}>
              <img src={IMAGES.LOGO} alt="" />
            </div>
            <div onClick={logout} className={styles["logout"]}>
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className={styles["devices-page-container"]}>
        <AddDeviceModal
          open={openAddDeviceModal}
          setOpen={setOpenAddDeviceModal}
          getDevices={getDevices}
        />
        <h1 className={styles["devices-page__title"]}>DEVICES</h1>
        <div className={styles["holder"]}>
          <div className={styles["devices-page__btn-container"]}>
            <ButtonComponent
              onClick={() => setOpenAddDeviceModal(true)}
              text={
                <div className={styles["add-device-btn"]}>
                  Add device {ICONS.ADD}
                </div>
              }
            />
          </div>
          {
           devices?.map((device) => (
            <Device device={device} getDevices={getDevices} />
          ))
          }
        </div>
      </div>
    </div>
  );
}
