import React from "react";
import styles from "./style.module.scss";
export default function PaymentSuccess() {
  
  return (
    <div className={styles["success_container"]}>
      <svg
        width="307"
        height="273"
        viewBox="0 0 307 273"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M156.964 272.919C232.052 272.919 292.923 212.048 292.923 136.96C292.923 61.8712 232.052 1 156.964 1C81.8751 1 21.0039 61.8712 21.0039 136.96C21.0039 212.048 81.8751 272.919 156.964 272.919Z"
          fill="url(#paint0_linear_242_5)"
        />
        <path
          d="M278 154.781C278.011 164.775 274.39 174.431 267.813 181.949C261.236 189.468 252.152 194.336 242.254 195.645C240.445 195.876 238.623 195.994 236.799 196H99.8255C96.854 195.993 93.8879 195.748 90.9556 195.268C78.3649 193.093 67.0228 186.333 59.1124 176.29C51.202 166.247 47.2825 153.629 48.1083 140.867C48.9341 128.104 54.4468 116.099 63.5853 107.161C72.7239 98.2225 84.8423 92.9839 97.608 92.4531C104.224 78.0727 115.333 66.2358 129.258 58.728C143.183 51.2202 159.17 48.4486 174.806 50.8314C190.443 53.2141 204.881 60.6219 215.943 71.9374C227.006 83.2529 234.092 97.8623 236.134 113.562H236.755C242.168 113.553 247.53 114.613 252.534 116.681C257.537 118.748 262.085 121.783 265.916 125.611C269.746 129.44 272.786 133.987 274.859 138.992C276.933 143.997 278 149.362 278 154.781Z"
          fill="url(#paint1_linear_242_5)"
        />
        <path
          d="M259.951 0.584906L262.529 5.80128C262.606 5.95594 262.719 6.08973 262.858 6.19114C262.998 6.29256 263.16 6.35857 263.331 6.38349L269.095 7.21341C269.29 7.24326 269.473 7.32662 269.624 7.4542C269.774 7.58178 269.886 7.74858 269.947 7.93602C270.009 8.12346 270.017 8.32419 269.97 8.51584C269.924 8.70749 269.825 8.88255 269.685 9.02151L265.521 13.0807C265.397 13.2011 265.304 13.3497 265.251 13.5139C265.197 13.678 265.185 13.8527 265.215 14.0227L266.2 19.7544C266.233 19.9504 266.211 20.1519 266.136 20.3361C266.061 20.5203 265.936 20.6798 265.775 20.7967C265.614 20.9136 265.423 20.983 265.225 20.9973C265.026 21.0115 264.828 20.9699 264.651 20.8772L259.496 18.1651C259.343 18.0849 259.173 18.0431 259 18.0431C258.827 18.0431 258.657 18.0849 258.504 18.1651L253.349 20.8772C253.173 20.9694 252.974 21.0106 252.776 20.9961C252.577 20.9816 252.387 20.9121 252.226 20.7953C252.065 20.6785 251.941 20.5192 251.866 20.3352C251.791 20.1513 251.769 19.9501 251.802 19.7544L252.785 14.0227C252.815 13.8527 252.803 13.678 252.749 13.5139C252.696 13.3497 252.603 13.2011 252.479 13.0807L248.315 9.02151C248.175 8.88255 248.076 8.70749 248.03 8.51584C247.983 8.32419 247.991 8.12346 248.053 7.93602C248.114 7.74858 248.226 7.58178 248.376 7.4542C248.527 7.32662 248.71 7.24326 248.905 7.21341L254.669 6.37807C254.84 6.35314 255.002 6.28714 255.142 6.18572C255.281 6.0843 255.394 5.95052 255.471 5.79586L258.049 0.579482C258.139 0.404553 258.276 0.25783 258.444 0.155555C258.612 0.05328 258.805 -0.000557191 259.002 4.34825e-06C259.198 0.000565888 259.391 0.0555037 259.559 0.158736C259.726 0.261969 259.862 0.409468 259.951 0.584906Z"
          fill="url(#paint2_linear_242_5)"
        />
        <path
          d="M22.2717 106.086L27.0771 115.774C27.2196 116.061 27.4302 116.309 27.6906 116.498C27.951 116.686 28.2535 116.809 28.572 116.855L39.3133 118.396C39.677 118.452 40.0181 118.607 40.2986 118.844C40.5792 119.08 40.7881 119.39 40.9021 119.738C41.0162 120.086 41.0309 120.459 40.9446 120.815C40.8583 121.171 40.6744 121.496 40.4134 121.754L32.6519 129.293C32.421 129.516 32.2485 129.792 32.1491 130.097C32.0497 130.402 32.0265 130.726 32.0816 131.042L33.9173 141.687C33.9803 142.051 33.9396 142.425 33.7998 142.767C33.6601 143.109 33.4268 143.405 33.1266 143.622C32.8263 143.839 32.471 143.968 32.1008 143.995C31.7306 144.021 31.3604 143.944 31.0321 143.772L21.4246 138.735C21.1394 138.586 20.8221 138.509 20.5 138.509C20.1779 138.509 19.8606 138.586 19.5754 138.735L9.96792 143.772C9.63968 143.943 9.26988 144.02 8.90027 143.993C8.53066 143.966 8.17597 143.837 7.87624 143.62C7.57652 143.403 7.34369 143.107 7.20405 142.765C7.06442 142.424 7.02354 142.05 7.08603 141.687L8.91843 131.042C8.97346 130.726 8.95029 130.402 8.85091 130.097C8.75153 129.792 8.57895 129.516 8.34812 129.293L0.586579 121.754C0.325583 121.496 0.141703 121.171 0.0554202 120.815C-0.0308628 120.459 -0.0161621 120.086 0.0978832 119.738C0.211928 119.39 0.420843 119.08 0.70136 118.844C0.981877 118.607 1.32298 118.452 1.68669 118.396L12.428 116.845C12.7465 116.799 13.049 116.676 13.3094 116.488C13.5698 116.299 13.7804 116.051 13.9229 115.764L18.7283 106.076C18.8956 105.751 19.1499 105.479 19.4631 105.289C19.7763 105.099 20.1363 104.999 20.5031 105C20.8699 105.001 21.2293 105.103 21.5414 105.295C21.8536 105.487 22.1063 105.76 22.2717 106.086Z"
          fill="url(#paint3_linear_242_5)"
        />
        <path
          d="M220 176H124.531L117 193H212.462L220 176Z"
          fill="url(#paint4_linear_242_5)"
        />
        <path
          d="M172.358 174H109L116.649 193H180L172.358 174Z"
          fill="url(#paint5_linear_242_5)"
        />
        <path
          d="M222 174H187.567L180 193H214.433L222 174Z"
          fill="url(#paint6_linear_242_5)"
        />
        <path d="M215 193H151V256H215V193Z" fill="url(#paint7_linear_242_5)" />
        <path d="M180 193H117V256H180V193Z" fill="url(#paint8_linear_242_5)" />
        <g filter="url(#filter0_d_242_5)">
          <path
            d="M122.901 154H74.0989C70.1783 154 67 157.18 67 161.103V230.897C67 234.82 70.1783 238 74.0989 238H122.901C126.822 238 130 234.82 130 230.897V161.103C130 157.18 126.822 154 122.901 154Z"
            fill="url(#paint9_linear_242_5)"
          />
        </g>
        <path
          d="M117.663 189H79.3368C78.0462 189 77 189.894 77 190.997V191.003C77 192.106 78.0462 193 79.3368 193H117.663C118.954 193 120 192.106 120 191.003V190.997C120 189.894 118.954 189 117.663 189Z"
          fill="url(#paint10_linear_242_5)"
        />
        <path
          d="M117.718 221H80.2824C79.0219 221 78 222.117 78 223.496V223.504C78 224.883 79.0219 226 80.2824 226H117.718C118.978 226 120 224.883 120 223.504V223.496C120 222.117 118.978 221 117.718 221Z"
          fill="url(#paint11_linear_242_5)"
        />
        <path
          d="M117.718 204H80.2824C79.0219 204 78 205.117 78 206.496V206.504C78 207.883 79.0219 209 80.2824 209H117.718C118.978 209 120 207.883 120 206.504V206.496C120 205.117 118.978 204 117.718 204Z"
          fill="url(#paint12_linear_242_5)"
        />
        <path
          d="M93.5489 164H82.4511C79.9928 164 78 165.996 78 168.458V171.542C78 174.004 79.9928 176 82.4511 176H93.5489C96.0072 176 98 174.004 98 171.542V168.458C98 165.996 96.0072 164 93.5489 164Z"
          fill="url(#paint13_linear_242_5)"
        />
        <path
          d="M274.272 192.086L279.077 201.774C279.22 202.061 279.43 202.309 279.691 202.498C279.951 202.686 280.253 202.809 280.572 202.855L291.313 204.396C291.677 204.452 292.018 204.607 292.299 204.844C292.579 205.08 292.788 205.39 292.902 205.738C293.016 206.086 293.031 206.459 292.945 206.815C292.858 207.171 292.674 207.496 292.413 207.754L284.652 215.293C284.421 215.516 284.248 215.792 284.149 216.097C284.05 216.402 284.027 216.726 284.082 217.042L285.917 227.687C285.98 228.051 285.94 228.425 285.8 228.767C285.66 229.109 285.427 229.405 285.127 229.622C284.826 229.839 284.471 229.968 284.101 229.995C283.731 230.021 283.36 229.944 283.032 229.772L273.425 224.735C273.139 224.586 272.822 224.509 272.5 224.509C272.178 224.509 271.861 224.586 271.575 224.735L261.968 229.772C261.64 229.943 261.27 230.02 260.9 229.993C260.531 229.966 260.176 229.837 259.876 229.62C259.577 229.403 259.344 229.107 259.204 228.765C259.064 228.424 259.024 228.05 259.086 227.687L260.918 217.042C260.973 216.726 260.95 216.402 260.851 216.097C260.752 215.792 260.579 215.516 260.348 215.293L252.587 207.754C252.326 207.496 252.142 207.171 252.055 206.815C251.969 206.459 251.984 206.086 252.098 205.738C252.212 205.39 252.421 205.08 252.701 204.844C252.982 204.607 253.323 204.452 253.687 204.396L264.428 202.845C264.747 202.799 265.049 202.676 265.309 202.488C265.57 202.299 265.78 202.051 265.923 201.764L270.728 192.076C270.896 191.751 271.15 191.479 271.463 191.289C271.776 191.099 272.136 190.999 272.503 191C272.87 191.001 273.229 191.103 273.541 191.295C273.854 191.487 274.106 191.76 274.272 192.086Z"
          fill="url(#paint14_linear_242_5)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M47.826 54.3248C50.6795 54.3248 52.9927 52.0116 52.9927 49.1581C52.9927 46.3046 50.6795 43.9913 47.826 43.9913C44.9725 43.9913 42.6592 46.3046 42.6592 49.1581C42.6592 52.0116 44.9725 54.3248 47.826 54.3248ZM47.826 58.1999C52.8196 58.1999 56.8678 54.1517 56.8678 49.1581C56.8678 44.1644 52.8196 40.1163 47.826 40.1163C42.8323 40.1163 38.7842 44.1644 38.7842 49.1581C38.7842 54.1517 42.8323 58.1999 47.826 58.1999Z"
          fill="url(#paint15_linear_242_5)"
        />
        <path
          d="M26.8087 186.617C26.4757 185.31 27.6814 184.134 28.979 184.498L40.443 187.72C41.7405 188.085 42.1568 189.717 41.1922 190.658L32.6702 198.976C31.7057 199.917 30.0837 199.461 29.7507 198.155L26.8087 186.617Z"
          fill="url(#paint16_linear_242_5)"
        />
        <path
          d="M296.207 151.422C297.514 151.09 298.689 152.298 298.323 153.595L295.086 165.055C294.72 166.352 293.087 166.766 292.147 165.8L283.84 157.267C282.9 156.301 283.358 154.68 284.665 154.349L296.207 151.422Z"
          fill="url(#paint17_linear_242_5)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M269.78 37.2334C272.072 37.2334 273.931 35.375 273.931 33.0826C273.931 30.7902 272.072 28.9319 269.78 28.9319C267.488 28.9319 265.629 30.7902 265.629 33.0826C265.629 35.375 267.488 37.2334 269.78 37.2334ZM269.78 40.3464C273.792 40.3464 277.044 37.0943 277.044 33.0826C277.044 29.0709 273.792 25.8188 269.78 25.8188C265.768 25.8188 262.516 29.0709 262.516 33.0826C262.516 37.0943 265.768 40.3464 269.78 40.3464Z"
          fill="url(#paint18_linear_242_5)"
        />
        <path
          d="M98.403 123C96.1818 131.922 103.377 150.668 114.483 152.155C135.032 154.906 131.258 130.775 118.999 135.31C104.409 140.707 119.01 163.796 139 169"
          stroke="#69F0AE"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="4 4"
        />
        <path
          d="M99.1482 104.39L100.906 107.868C100.958 107.971 101.035 108.06 101.131 108.127C101.226 108.195 101.337 108.239 101.453 108.256L105.383 108.809C105.516 108.829 105.641 108.884 105.743 108.969C105.846 109.055 105.922 109.166 105.964 109.291C106.006 109.416 106.011 109.549 105.98 109.677C105.948 109.805 105.881 109.922 105.785 110.014L102.946 112.72C102.861 112.801 102.798 112.9 102.762 113.009C102.726 113.119 102.717 113.235 102.737 113.348L103.409 117.17C103.432 117.3 103.417 117.435 103.366 117.557C103.315 117.68 103.229 117.787 103.119 117.864C103.01 117.942 102.88 117.989 102.744 117.998C102.609 118.008 102.473 117.98 102.353 117.918L98.8383 116.11C98.7339 116.057 98.6178 116.029 98.5 116.029C98.3822 116.029 98.2661 116.057 98.1617 116.11L94.6468 117.918C94.5267 117.98 94.3914 118.007 94.2562 117.997C94.121 117.988 93.9912 117.941 93.8816 117.864C93.7719 117.786 93.6867 117.679 93.6356 117.557C93.5845 117.434 93.5696 117.3 93.5924 117.17L94.2628 113.348C94.283 113.235 94.2745 113.119 94.2381 113.009C94.2018 112.9 94.1386 112.801 94.0542 112.72L91.2146 110.014C91.1191 109.922 91.0518 109.805 91.0203 109.677C90.9887 109.549 90.9941 109.416 91.0358 109.291C91.0775 109.166 91.154 109.055 91.2566 108.969C91.3592 108.884 91.484 108.829 91.6171 108.809L95.5468 108.252C95.6634 108.235 95.774 108.191 95.8693 108.124C95.9646 108.056 96.0416 107.967 96.0938 107.864L97.8518 104.386C97.913 104.27 98.006 104.172 98.1206 104.104C98.2352 104.036 98.3669 104 98.5011 104C98.6353 104 98.7668 104.037 98.881 104.106C98.9952 104.175 99.0877 104.273 99.1482 104.39Z"
          fill="url(#paint19_linear_242_5)"
        />
        <path
          d="M215.344 120.549C227.564 144.331 195.189 169.721 186.227 174.425"
          stroke="#69F0AE"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="4 4"
        />
        <path
          d="M211.288 101.47L213.942 104.323C214.021 104.407 214.12 104.471 214.23 104.51C214.34 104.548 214.459 104.56 214.575 104.544L218.504 103.984C218.638 103.966 218.773 103.985 218.895 104.038C219.017 104.091 219.122 104.177 219.196 104.285C219.271 104.394 219.313 104.521 219.319 104.652C219.324 104.784 219.292 104.915 219.226 105.03L217.249 108.418C217.19 108.519 217.157 108.632 217.152 108.747C217.148 108.862 217.172 108.976 217.223 109.08L218.929 112.564C218.987 112.683 219.01 112.816 218.995 112.949C218.98 113.081 218.928 113.207 218.844 113.312C218.76 113.417 218.648 113.498 218.521 113.545C218.393 113.591 218.255 113.602 218.123 113.576L214.244 112.815C214.129 112.793 214.01 112.798 213.896 112.831C213.783 112.864 213.679 112.923 213.594 113.003L210.719 115.716C210.621 115.808 210.499 115.872 210.366 115.901C210.234 115.929 210.096 115.92 209.969 115.876C209.842 115.832 209.731 115.753 209.648 115.65C209.565 115.546 209.513 115.421 209.499 115.29L209.082 111.433C209.07 111.318 209.029 111.209 208.964 111.114C208.898 111.019 208.81 110.941 208.707 110.887L205.228 109.076C205.11 109.013 205.013 108.92 204.947 108.806C204.882 108.692 204.85 108.562 204.855 108.43C204.86 108.299 204.903 108.171 204.978 108.06C205.053 107.95 205.157 107.862 205.28 107.806L208.9 106.18C209.008 106.132 209.102 106.059 209.174 105.967C209.247 105.876 209.296 105.769 209.318 105.655L210.041 101.826C210.068 101.697 210.13 101.577 210.221 101.48C210.312 101.383 210.429 101.312 210.558 101.275C210.687 101.238 210.823 101.237 210.952 101.271C211.081 101.306 211.197 101.374 211.288 101.47Z"
          fill="url(#paint20_linear_242_5)"
        />
        <path
          d="M130 103.656L155.623 129L199 86"
          stroke="#69F0AE"
          stroke-width="20"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_242_5"
            x="55"
            y="146"
            width="93"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3" dy="7" />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.587031 0 0 0 0 0.628324 0 0 0 0 0.6375 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_242_5"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_242_5"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_242_5"
            x1="155.915"
            y1="-43.2718"
            x2="157"
            y2="272.517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="#F5F3F3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_242_5"
            x1="234.353"
            y1="258.967"
            x2="119.774"
            y2="50.0946"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C3BFBF" />
            <stop offset="1" stop-color="#F2EFEF" stop-opacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_242_5"
            x1="249.775"
            y1="5.63405"
            x2="289.154"
            y2="21.0198"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#90FAC6" />
            <stop offset="0.70369" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_242_5"
            x1="3.30798"
            y1="115.463"
            x2="76.6289"
            y2="144.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#90FAC6" />
            <stop offset="0.70369" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_242_5"
            x1="116.883"
            y1="179.923"
            x2="220.404"
            y2="183.996"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BEBBBB" />
            <stop offset="1" stop-color="#BFBBBB" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_242_5"
            x1="144.5"
            y1="174"
            x2="144.5"
            y2="193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FBFAFA" />
            <stop offset="1" stop-color="#F1EEEE" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_242_5"
            x1="201"
            y1="174"
            x2="201"
            y2="193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#CEC9C9" />
            <stop offset="1" stop-color="#DCD5D5" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_242_5"
            x1="149.078"
            y1="240.242"
            x2="241.542"
            y2="241.727"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.392237" stop-color="#BFBABA" />
            <stop offset="1" stop-color="#F2EFEF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_242_5"
            x1="134.376"
            y1="227.785"
            x2="168.566"
            y2="236.694"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FDFDFD" />
            <stop offset="1" stop-color="#F2EFEF" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_242_5"
            x1="65.1084"
            y1="216.99"
            x2="185.859"
            y2="226.901"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#E0D9D9" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_242_5"
            x1="124.99"
            y1="193.119"
            x2="73.2895"
            y2="193.119"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F2E9E9" />
            <stop offset="1" stop-color="#B5B3B3" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_242_5"
            x1="124.874"
            y1="226.149"
            x2="74.3758"
            y2="226.149"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EAE2E2" />
            <stop offset="1" stop-color="#C5C1C1" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_242_5"
            x1="124.874"
            y1="209.149"
            x2="74.3758"
            y2="209.149"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EBE3E3" />
            <stop offset="1" stop-color="#C1BDBD" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_242_5"
            x1="111.868"
            y1="175.885"
            x2="75.4688"
            y2="175.885"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.243799" stop-color="#EBE2E2" />
            <stop offset="1" stop-color="#BBB8B8" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_242_5"
            x1="255.308"
            y1="201.463"
            x2="328.629"
            y2="230.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#90FAC6" />
            <stop offset="0.70369" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_242_5"
            x1="38.7637"
            y1="44.2897"
            x2="56.9661"
            y2="44.4079"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8EFAC5" />
            <stop offset="1" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_242_5"
            x1="21.9619"
            y1="194.37"
            x2="37.0065"
            y2="179.876"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8EFAC5" />
            <stop offset="1" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_242_5"
            x1="288.46"
            y1="146.565"
            x2="302.935"
            y2="161.628"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8EFAC5" />
            <stop offset="1" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_242_5"
            x1="262.5"
            y1="29.1716"
            x2="277.123"
            y2="29.2665"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8EFAC5" />
            <stop offset="1" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_242_5"
            x1="92.2102"
            y1="107.756"
            x2="118.897"
            y2="118.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#90FAC6" />
            <stop offset="0.70369" stop-color="#69F0AE" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_242_5"
            x1="205.557"
            y1="106.63"
            x2="234.156"
            y2="109.465"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#90FAC6" />
            <stop offset="0.70369" stop-color="#69F0AE" />
          </linearGradient>
        </defs>
      </svg>
      <div  className={styles["texts"]}>
        <p className={styles["texts_top"]}>Congratulations everything was</p> 
        <p className={styles["texts_ok"]}> Successful</p>
      </div>
    </div>
  );
}
