import React from "react";

import Payment from "./payment/Payment";
import LoginSection from "./login/LoginSection";
import Registration from "./registration/Registration";
import Reseller from "./reseller/Reseller";
import Help from "./help/Help";
import News from "./news/News";

export default function HomePage() {
  return (
    <>
      <LoginSection />
      {/* <Payment /> */}
      <Registration />
      <Reseller />
      <News/>
      <Help />
    </>
  );
}
