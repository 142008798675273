import React, { useState, useEffect } from "react";

import classNames from "classnames";

import DirectPayment from "./components/DirectPayment";
import PaymentWithCoupon from "./components/PaymentWithCoupon";
import ActivateByVoucherCode from "./components/ActivateByVoucherCode";

import REQUESTS from "../../api/requests";

import styles from "./paymentPage.module.scss";

export default function PaymentPage() {
    const [paymentType, setPaymentType] = useState("direct-pay");
    const [appInfo, setAppInfo] = useState({
      priceMad: "",
      priceEuro: "",
      price: "",
    });
  
    const getAppInfo = () => {
      REQUESTS.GET_APP_INFO().then((data) => {
        if (!data.error) {
          setAppInfo({
            priceMad: data.message.price + " MAD",
            priceEuro: (data.message.price / 10).toFixed() + " EUR",
            price: data.message.price / 10,
          });
        }
      });
    };
  
    useEffect(() => {
      getAppInfo();
    }, []);
  
    return (
      <div
        className={classNames(
          styles["payment-section"],
          styles["observable-section"]
        )}
        id="activation"
      >
        <div className={styles["holder"]}>
          <div className={styles["payment-section__wrapper"]}>
            <h1 className={styles["title"]}>ACTIVATION</h1>
            <div className={styles["payment-type"]}>
              <div
                className={classNames(styles["payment-type__direct-pay"], {
                  [styles["active"]]: paymentType === "direct-pay",
                })}
                onClick={() => setPaymentType("direct-pay")}
              >
                Direct pay
              </div>
              <div
                className={classNames(styles["payment-type__coupon"], {
                  [styles["active"]]: paymentType === "coupon",
                })}
                onClick={() => setPaymentType("coupon")}
              >
                Coupon activation
              </div>
            </div>
            <div
              style={{ display: "flex", gap: 130 }}
              className={styles["payment-section__wrapper__main-content"]}
            >
              {paymentType === "direct-pay" ? (
                <DirectPayment price={appInfo.price} />
              ) : (
                <PaymentWithCoupon />
              )}
              <div className={styles["payment-section__wrapper-desc"]}>
                <span className={styles["desc__text"]}>
                  <span className={styles["desc__text__subtext"]}>
                    Important!
                  </span>
                  We do not provide any content with this APP and you must have a
                  playlist to use it. Please make sure you have a playlist before
                  purchase.
                </span>
                <h2 className={styles["desc__title"]}>
                  Pay once and active your <br /> device forever.
                </h2>
                <ol className={styles["steps"]}>
                  <li>Enter your email address</li>
                  <li>Enter your MAC address</li>
                  <li>Agree with our privacy and terms of use</li>
                  <li>Hit pay and join</li>
                </ol>
                <span className={styles["bottom-text"]}>
                  The activation price is a one time payment per MAC address of{" "}
                  {appInfo.priceMad}(Approximately {appInfo.priceEuro})
                </span>
              </div>
            </div>
          </div>
        </div>
        <ActivateByVoucherCode />
      </div>
    );
  }
  