import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./components.module.scss";

export default function Modal({
  children,
  open = false,
  setOpen,
}) {
  // useEffect(() => {
  //   if (open) {
  //   document.body.style.overflow="hidden"
  //   } else {
  //     document.body.style.overflow= "auto";
  //   }
  // }, [open])
  return (ReactDOM.createPortal(
    <AnimatePresence >
      {open && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
          }}
          className={styles["modal"]}
          onClick={(e) => setOpen(false)}
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              x: 0,
            }}
          >
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              className={styles["modal-content"]}
            >
              <div onClick={(e) => e.stopPropagation()}>{children}</div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,document.body)
  );
}
