export function parseFullDate(date) {
  const dateObj = new Date(date);

  let year = dateObj.getFullYear();
  let day = dateObj.getDate();
  let month = dateObj.getMonth();
  let minutes = dateObj.getMinutes();
  let hours = dateObj.getHours();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function parseDate(date) {
  const dateObj = new Date(date);

  let year = dateObj.getFullYear();
  let day = dateObj.getDate();
  let month = dateObj.getMonth();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return `${day}/${month}/${year}`;
}
