import React from "react";
import { useSearchParams } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../api/requests";
import { PAYPAL_CLIENT_ID } from "../../../api/config";

export default function Paypal({
  price,
  mac,
  email,
  resetForm,
  setCheckboxes,
}) {
  let [searchParams] = useSearchParams();

  const activateReferral = (cb) => {
    if (searchParams.get("code") && searchParams.get("reseller_id")) {
      REQUESTS.ACTIVATION_REFERRAL_DEVICE({
        mac,
        code: searchParams.get("code"),
        resseler_id: searchParams.get("resseler_id"),
      });
    } else {
      cb();
    }
  };
  const handlePayment = (details) => {
    let id = details.purchase_units[0].payments.captures[0].id;
    activateReferral(() => {
      if (mac && email) {
        REQUESTS.PAYPAL_PAYMENT({
          mac,
          price,
          email,
          id,
          detiles: JSON.stringify(details),
        }).then((data) => {
          if (data.error) {
            toast.error("Payment was failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("Payment was successfully done", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            resetForm();
            setCheckboxes({
              all: false,
              privacy: false,
              termsOfUse: false,
              termsOfSales: false,
            });
          }
        });
      }
    });
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <PayPalScriptProvider
        options={{
          "client-id": PAYPAL_CLIENT_ID,
          currency: "USD",
          intent: "capture",
        }}
      >
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: price,
                  },
                  description: mac,
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              handlePayment(details);
            });
          }}
          onError={(err) => {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
}
