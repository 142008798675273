import REQUESTS from "../api/requests";

export function validateMac( mac,setMessage) {
  REQUESTS.VALIDATE_MAC(mac).then((response) => {
    if (!response.message) {
      setMessage("Mac address is not valid");
    } else {
      setMessage("Mac address is valid");
    }
  });
}
