import React from "react";
import { Link } from "react-router-dom";

import styles from "./help.module.scss";

export default function Help() {
  return (
    <div className={styles["help-section"]} id="help">
      <h1 className={styles["help-section__title"]}>HELP</h1>
      <span className={styles["help-section__alert"]}>
        Login to your account
      </span>
      <span className={styles["help-section__desc"]}>
        Download on your TV iRed IPTV APP and run it.Folllow activation link
        for activating TV device
      </span>

      <button
        className={styles["help-section__btn"]}
        onClick={() => {
          document.getElementById("activation")?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        <Link to="/">Activation link </Link>
      </button>

      <span className={styles["help-section__txt"]}>
        For more help contact us:
        <a
          href="mailto:support@iredtv.net"
          className={styles["help-section__mail"]}
        >
          support@iredtv.net
        </a>
      </span>
    </div>
  );
}
