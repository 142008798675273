const ICONS = {
  // a
  ADD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 29.9"
    >
      <path
        id="Union_2"
        data-name="Union 2"
        d="M14.691,30a3,3,0,0,1-3-3V18.257H3a3,3,0,0,1-3-3v-.516a3,3,0,0,1,3-3h8.691V3a3,3,0,0,1,3-3h.617a3,3,0,0,1,3,3v8.742H27a3,3,0,0,1,3,3v.516a3,3,0,0,1-3,3H18.308V27a3,3,0,0,1-3,3Z"
        fill="#fff"
        opacity="0.503"
      />
    </svg>
  ),
  ARROW_UP: (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10827 12.4271L13.0083 0.491634C13.1499 0.349967 13.3034 0.249856 13.4687 0.1913C13.634 0.1318 13.811 0.102051 13.9999 0.102051C14.1888 0.102051 14.3659 0.1318 14.5312 0.1913C14.6965 0.249856 14.8499 0.349967 14.9916 0.491634L26.927 12.4271C27.2576 12.7576 27.4229 13.1708 27.4229 13.6666C27.4229 14.1625 27.2458 14.5875 26.8916 14.9416C26.5374 15.2958 26.1242 15.4729 25.652 15.4729C25.1798 15.4729 24.7666 15.2958 24.4124 14.9416L13.9999 4.52913L3.58743 14.9416C3.25688 15.2722 2.84982 15.4375 2.36627 15.4375C1.88177 15.4375 1.46243 15.2604 1.10827 14.9062C0.754101 14.552 0.577019 14.1389 0.577019 13.6666C0.577019 13.1944 0.754101 12.7812 1.10827 12.4271Z"
        fill="#B5B3B3"
      />
    </svg>
  ),
  ARROW_DOWN: (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10827 3.1479L13.0083 15.0833C13.1499 15.225 13.3034 15.3251 13.4687 15.3837C13.634 15.4432 13.811 15.4729 13.9999 15.4729C14.1888 15.4729 14.3659 15.4432 14.5312 15.3837C14.6965 15.3251 14.8499 15.225 14.9916 15.0833L26.927 3.1479C27.2576 2.81735 27.4229 2.40415 27.4229 1.90832C27.4229 1.41248 27.2458 0.987485 26.8916 0.633318C26.5374 0.279152 26.1242 0.102068 25.652 0.102068C25.1798 0.102068 24.7666 0.279152 24.4124 0.633318L13.9999 11.0458L3.58743 0.633318C3.25688 0.302763 2.84982 0.137485 2.36627 0.137485C1.88177 0.137485 1.46243 0.314568 1.10827 0.668735C0.754101 1.0229 0.577019 1.4361 0.577019 1.90832C0.577019 2.38054 0.754101 2.79373 1.10827 3.1479Z"
        fill="#B5B3B3"
      />
    </svg>
  ),
  ARROW_BACK: (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5338 18.5554L0.511719 9.53331L9.5338 0.51123L11.1409 2.09009L4.8254 8.40555H18.5559V10.6611H4.8254L11.1409 16.9765L9.5338 18.5554Z"
        fill="black"
      />
    </svg>
  ),
  // b
  // c
  CLOSE_ICON: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5033 10.0001L19.1205 0.920417C19.2482 0.769524 19.1408 0.540283 18.9435 0.540283H16.6278C16.4915 0.540283 16.3609 0.601221 16.2709 0.705685L9.98856 8.19519L3.70619 0.705685C3.61914 0.601221 3.48856 0.540283 3.34927 0.540283H1.03365C0.836324 0.540283 0.728957 0.769524 0.856636 0.920417L8.47382 10.0001L0.856636 19.0798C0.828035 19.1134 0.809686 19.1546 0.803767 19.1983C0.797849 19.2421 0.804609 19.2866 0.823246 19.3266C0.841883 19.3666 0.871614 19.4005 0.908909 19.4241C0.946204 19.4477 0.989495 19.4602 1.03365 19.4599H3.34927C3.48565 19.4599 3.61623 19.399 3.70619 19.2945L9.98856 11.805L16.2709 19.2945C16.358 19.399 16.4886 19.4599 16.6278 19.4599H18.9435C19.1408 19.4599 19.2482 19.2307 19.1205 19.0798L11.5033 10.0001Z"
        fill="black"
      />
    </svg>
  ),

  // d
  DELETE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.55"
      height="22.564"
      viewBox="0 0 17.55 22.564"
    >
      <path
        id="Icon_material-delete"
        data-name="Icon material-delete"
        d="M8.754,24.557a2.514,2.514,0,0,0,2.507,2.507H21.289A2.514,2.514,0,0,0,23.8,24.557V9.514H8.754Zm16.3-18.8H20.662L19.409,4.5H13.141L11.887,5.754H7.5V8.261H25.05Z"
        transform="translate(-7.5 -4.5)"
        fill="#f01d23"
        opacity="0.8"
      />
    </svg>
  ),
  // e
  EDIT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.752"
      height="21.752"
      viewBox="0 0 21.752 21.752"
    >
      <g
        id="Icon_feather-edit"
        data-name="Icon feather-edit"
        transform="translate(1 1)"
      >
        <path
          id="Path_1626"
          data-name="Path 1626"
          d="M11.835,6H4.963A1.963,1.963,0,0,0,3,7.963V21.706A1.963,1.963,0,0,0,4.963,23.67H18.706a1.963,1.963,0,0,0,1.963-1.963V14.835"
          transform="translate(-3 -3.918)"
          fill="none"
          stroke="#6d68f2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_1627"
          data-name="Path 1627"
          d="M22.307,3.428a2.082,2.082,0,1,1,2.945,2.945L15.927,15.7,12,16.68l.982-3.927Z"
          transform="translate(-6.11 -2.818)"
          fill="none"
          stroke="#6d68f2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  ),
  // f
  // g
  // h
  // i
  // j
  // k
  // l
  // m
  MENU_ICON: (
    <svg
      width="39"
      height="27"
      viewBox="0 0 39 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 5V0.75H38.625V5H0.375ZM0.375 15.625H38.625V11.375H0.375V15.625ZM0.375 26.25H38.625V22H0.375V26.25Z"
        fill="#1C1B1F"
      />
    </svg>
  ),
  // n
  // o
  // p
  // q
  // r
  RESELLER_PANEL_ICON: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.563"
      height="32.5"
      viewBox="0 0 36.563 32.5"
    >
      <path
        id="Icon_awesome-external-link-alt"
        data-name="Icon awesome-external-link-alt"
        d="M36.563,1.523V9.647a1.525,1.525,0,0,1-2.6,1.077L31.7,8.458,16.237,23.916a1.523,1.523,0,0,1-2.154,0L12.646,22.48a1.523,1.523,0,0,1,0-2.154L28.1,4.867,25.838,2.6A1.525,1.525,0,0,1,26.916,0h8.123A1.523,1.523,0,0,1,36.563,1.523ZM25.837,17.189,24.821,18.2a1.523,1.523,0,0,0-.446,1.077v9.156H4.063V8.125H20.82A1.524,1.524,0,0,0,21.9,7.679l1.016-1.016a1.523,1.523,0,0,0-1.077-2.6H3.047A3.047,3.047,0,0,0,0,7.109V29.453A3.047,3.047,0,0,0,3.047,32.5H25.391a3.047,3.047,0,0,0,3.047-3.047V18.266A1.523,1.523,0,0,0,25.837,17.189Z"
        fill="#fff"
      />
    </svg>
  ),
  // s
  // t
  // u
  // v
  // w
  // x
  // y
  // z
};

export default ICONS;
