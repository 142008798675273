import { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../api/requests";

import Modal from "../../../components/Modal";

import ButtonComponent from "../../../components/ButtonComponent";
import MessageComponent from "../../../components/MessageComponent";

import styles from "../paymentPage.module.scss";

export default function ActivateByVoucherCode() {
  const [activate, setActivate] = useState(false);

  const validationSchema = Yup.object().shape({
    mac: Yup.string().required("Mac is required"),
    code: Yup.string().required("Voucher code is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        mac: "",
        code: "",
      },
      onSubmit: (values) => {
        voucherCodeActivation();
      },
      validationSchema,
    });

  const voucherCodeActivation = () => {
    REQUESTS.ACTIVATE_BY_VOUCHER_CODE(values).then((res) => {
      if (res.error) {
        setActivate(false);
        toast.error(res.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setActivate(false);
        resetForm();
        toast.success("Device activated with success", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={styles["activate-by-voucher-code"]}>
        <span>
          Activate TV device by <br /> voucher code for LG / Samsung QA team
        </span>
        <ButtonComponent text="Activate" onClick={() => setActivate(true)} />
      </div>
      <Modal setOpen={setActivate} open={activate} closeBtn={true}>
        <div className={styles["modal-content"]}>
          <h1 className={styles["vocher-modal-title"]}>
            Voucher code activation for <br/>Samsung and Lg Qa team
          </h1>
          <form onSubmit={handleSubmit} className={styles["voucher-form"]}>
            <input
              type="text"
              name="mac"
              placeholder="Enter your mac"
              className={styles["voucher-form__inp"]}
              value={values.mac}
              onChange={handleChange}
            />
            <MessageComponent
              message={errors.mac && touched.mac ? errors.mac : null}
            />
            <input
              type="text"
              name="code"
              placeholder="Enter voucher code"
              className={styles["voucher-form__inp"]}
              value={values.code}
              onChange={handleChange}
            />
            <MessageComponent
              message={errors.code && touched.code ? errors.code : null}
            />
            <ButtonComponent text="ACTIVATE" style={{ marginTop: 45 }} />
          </form>
        </div>
      </Modal>
    </div>
  );
}
