import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Sidebar from "./Sidebar.jsx";
import ICONS from "../../../config/icons.js";
import IMAGES from "../../../config/images.js";

import styles from "./header.module.scss";

export default function Header() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [targetEl, setTargetEl] = useState("login");
  const [isVisible, setIsVisible] = useState(false);
  let target = document.querySelectorAll(".observable-section");

  useEffect(() => {
    let id;
    if (window.location.pathname == "/" && targetEl) {
      id = setTimeout(() => {
        document.getElementById(targetEl)?.scrollIntoView({
          behavior: "smooth",
        });
        setTargetEl(null);
      }, 50);
    }
    return () => clearTimeout(id);
  }, [targetEl]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          console.log(e);
          if (e.isIntersecting) {
            setIsVisible(e.isIntersecting);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    target.forEach((section) => console.log(observer.observe(section)));
  }, [target]);


  return (
    <header>
      <div className={styles["holder"]}>
        <div className={styles["wrapper"]}>
          <Link to="/">
            <div
              className={styles["logo-wrapper"]}
              onClick={() => setTargetEl("login")}
            >
              <img src={IMAGES.LOGO} alt="" />
            </div>
          </Link>
          <div className={styles["menu-wrapper"]}>
            <ul className={styles["menu-list"]}>
              <li
                className={classNames(styles["menu-list__item"])}
                onClick={() => setTargetEl("login")}
                style={{ background: isVisible ? "red" : "" }}
              >
                <Link to="/"> Login</Link>
              </li>
              <li
                className={classNames(styles["menu-list__item"])}
                onClick={() => setTargetEl("activation")}
                style={{ background: isVisible ? "red" : "" }}
              >
                <Link to="/payment"> Activation</Link>
              </li>

              <li
                className={classNames(styles["menu-list__item"])}
                onClick={() => setTargetEl("registration")}
              >
                <Link to="/"> Registration</Link>
              </li>

              <li
                className={classNames(styles["menu-list__item"])}
                onClick={() => setTargetEl("reseller")}
              >
                <Link to="/"> Become a reseller</Link>
              </li>
              {localStorage.getItem("hasNews") ==='true' && (
                <li
                  className={classNames(styles["menu-list__item"])}
                  onClick={() => setTargetEl("news")}
                >
                  <Link to="/">News</Link>
                </li>
              )}

              <li
                className={classNames(styles["menu-list__item"])}
                onClick={() => setTargetEl("help")}
              >
                <Link to="/"> Help</Link>
              </li>
            </ul>
          </div>
          <div
            className={styles["menu-icon"]}
            onClick={() => setOpenDrawer(true)}
          >
            <div className={styles["menu-icon-container"]}>
              {ICONS.MENU_ICON}
            </div>
          </div>
          <Sidebar open={openDrawer} setOpen={setOpenDrawer} />
        </div>
      </div>
    </header>
  );
}
