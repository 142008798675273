import React from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../../api/requests";

import Modal from "../../../../components/Modal";
import MessageComponent from "../../../../components/MessageComponent";
import ButtonComponent from "../../../../components/ButtonComponent";

import styles from "../login.module.scss";

export default function CodeModal({ open, setOpen, mac }) {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    code: Yup.string().required("Code is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        code: "",
      },
      onSubmit: (values) => {
        validateMac();
      },
      validationSchema,
    });

  const validate = () => {
    REQUESTS.VALIDATE().then((data) => {
      if (data.error) {
        navigate("/");
      } else {
        navigate("devices");
        localStorage.setItem("login_by", "mac");
      }
    });
  };

  const validateMac = () => {
    REQUESTS.CONFIRM_LOGIN({ ...values, mac }).then((data) => {
      if (data.error) {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        localStorage.setItem("token", data.message);
        validate();
        resetForm();
      }
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal open={open} setOpen={setOpen}>
        <div className={styles["code-modal-content"]}>
        <h5 className={styles["code-modal-title"]}>Enter code</h5>
        <span className={styles["code-modal-text"]}>
          The code sent to your tv device
        </span>
        <form onSubmit={handleSubmit} className={styles["code-modal-form"]}>
          <input
            type="text"
            name="code"
            value={values.code}
            onChange={handleChange}
            placeholder="Enter your MAC address"
            className={styles["code-modal-form_inp"]}
          />
          <MessageComponent
            message={errors.mac && touched.mac ? errors.mac : null}
          />
          <ButtonComponent
            type="submit"
            text="CONFIRM CODE"
            style={{ float: "right", marginTop: 45 }}
          />
        </form>
        </div>
       
      </Modal>
    </>
  );
}
