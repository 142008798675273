import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./footer.module.scss";

export default function Footer() {
  const [targetEl, setTargetEl] = useState(null);

  useEffect(() => {
    let id;
    if (window.location.pathname == "/" && targetEl) {
      id = setTimeout(() => {
        document.getElementById(targetEl)?.scrollIntoView({
          behavior: "smooth",
        });
        setTargetEl(null);
      }, 50);
    }
    return () => clearTimeout(id);
  }, [targetEl]);
  return (
    <div className={styles["footer-section"]}>
      <div
        className={styles["i-red-logo-container"]}
        onClick={() => setTargetEl("login")}
      >
        <svg
          width="134"
          height="48"
          viewBox="0 0 134 48"
          className={styles["i-red"]}
        >
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_779"
                data-name="Rectangle 779"
                width="134"
                height="48"
                transform="translate(282 18)"
                fill="#fff"
              />
            </clipPath>
          </defs>
          <g
            id="Mask_Group_4"
            data-name="Mask Group 4"
            transform="translate(-282 -18)"
            clipPath="url(#clipPath)"
          >
            <g id="Layer_x0020_1" transform="translate(283.221 19.08)">
              <g id="_105553169278464">
                <g
                  id="Group_69"
                  data-name="Group 69"
                  transform="translate(4.84)"
                >
                  <rect
                    id="Rectangle_777"
                    data-name="Rectangle 777"
                    width="4.741"
                    height="17.725"
                    rx="2.371"
                    transform="translate(0.387 23.614)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_778"
                    data-name="Rectangle 778"
                    width="4.741"
                    height="23.095"
                    rx="2.371"
                    transform="translate(54.4 18.244)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="2.758"
                    cy="2.758"
                    r="2.758"
                    transform="translate(0 15.365)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1633"
                    data-name="Path 1633"
                    d="M462.506,776.632c-.062,1.184-.167,3.541-.315,4.935s-.341,1.828-.657,2.088a2.113,2.113,0,0,1-1.295.369,11.2,11.2,0,0,1-1.579-.076,1.129,1.129,0,0,1-.77-.273c-.176-.176-.345-.481-.365-1.487s.108-2.713.317-5.679.5-7.19.7-9.647a13.832,13.832,0,0,1,.553-3.731,3.634,3.634,0,0,1,.982-1.431,3.542,3.542,0,0,1,1.635-.777,15.488,15.488,0,0,1,3.331-.249c1.663,0,4.044.068,5.543.2a7.151,7.151,0,0,1,2.742.609,5.717,5.717,0,0,1,1.848,1.359,5.384,5.384,0,0,1,1.334,2.73c.016.092.029.186.041.283a7.489,7.489,0,0,0-1.465.947,8.867,8.867,0,0,0-2.688,4.456,20.484,20.484,0,0,0-.68,5.557,9.314,9.314,0,0,0,1.121,5.054,6.961,6.961,0,0,0,1.978,2c-.629.044-1.642.06-2.278.046a2.469,2.469,0,0,1-1.222-.217,3.243,3.243,0,0,1-1.135-1.114c-.516-.717-1.217-1.9-1.893-3.089s-1.325-2.372-1.707-3.043-.5-.825-.609-.908-.227-.093-.6-.093-1.016.01-1.508.01-.836-.01-1.021-.017a2.044,2.044,0,0,0-.233-.006c-.023,0-.043.015-.105,1.2Zm5.486-11.567c-.793-.018-2.016-.018-2.789,0a4.441,4.441,0,0,0-1.333.149.872.872,0,0,0-.5.406,3.545,3.545,0,0,0-.233,1.372c-.074.94-.17,2.574-.194,3.4s.024.844.871.853,2.493.009,3.618-.018a11.936,11.936,0,0,0,2.192-.182,2.5,2.5,0,0,0,1.125-.519,2.785,2.785,0,0,0,.871-1.214,4.566,4.566,0,0,0,.221-1.918,2.238,2.238,0,0,0-.677-1.527,3.526,3.526,0,0,0-1.524-.644A7.626,7.626,0,0,0,467.992,765.065Z"
                    transform="translate(-451.473 -742.566)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_1634"
                    data-name="Path 1634"
                    d="M1108.829,972.365a7.785,7.785,0,0,0-2.373-.72,12.388,12.388,0,0,0-3.785.081,8.538,8.538,0,0,0-4.012,1.678,7.864,7.864,0,0,0-2.369,3.966,19.706,19.706,0,0,0-.641,5.282,8.349,8.349,0,0,0,.968,4.524,6.928,6.928,0,0,0,3.673,2.677,13.241,13.241,0,0,0,4.523.49,23.014,23.014,0,0,0,3.809-.365,5.509,5.509,0,0,0,2.033-.787c.08-.055.152-.109.218-.164a6.633,6.633,0,0,1-1.653-3.268q-.051-.226-.092-.458l-.186.085a6.247,6.247,0,0,1-1.7.476,16.6,16.6,0,0,1-3.347.2,6.44,6.44,0,0,1-2.5-.618,2.262,2.262,0,0,1-1.1-1,2.577,2.577,0,0,1-.206-1.145,2.992,2.992,0,0,1,.073-.687c.032-.115.06-.105.682-.1s1.841,0,3.608,0c1.417,0,3.186,0,4.448,0a22.964,22.964,0,0,1,.184-2.8,12.726,12.726,0,0,1,1.852-5.578,7.125,7.125,0,0,0-.828-.911,5.194,5.194,0,0,0-1.282-.851Zm-3.267,6.451c-1.19,0-3.144,0-4.124,0s-.986.018-1.01-.1a1.679,1.679,0,0,1,.043-.8,3.085,3.085,0,0,1,.94-1.567,4.239,4.239,0,0,1,2.374-.931,5.209,5.209,0,0,1,2.593.469,2.386,2.386,0,0,1,1.263,1.324,3.045,3.045,0,0,1,.137,1.36c-.04.277-.107.265-.353.256s-.673-.015-1.863-.018Z"
                    transform="translate(-1074.408 -948.41)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_1635"
                    data-name="Path 1635"
                    d="M1709.7,695.17a3.378,3.378,0,0,0-1.676-1.227,9.119,9.119,0,0,0-4.647-.53,7.081,7.081,0,0,0-4.423,2.727,11.307,11.307,0,0,0-1.843,5.35,17.671,17.671,0,0,0,.115,5.693,5.26,5.26,0,0,0,2.169,3.43,8.524,8.524,0,0,0,4.425,1.348,17.545,17.545,0,0,0,4.944-.591,4.914,4.914,0,0,0,2.566-1.377,8.561,8.561,0,0,0,1.953-3.041,22.622,22.622,0,0,0,.893-6.018c.223-3.12.483-7.776.6-10.385s.086-3.171-.108-3.531a1.293,1.293,0,0,0-1.117-.584,5.181,5.181,0,0,0-1.881.13,1.507,1.507,0,0,0-1.11,1.218,15.928,15.928,0,0,0-.329,3.118c-.049,1.172-.073,2.32-.105,3.1a8.582,8.582,0,0,1-.116,1.367c-.045.168-.1.088-.307-.2Zm-.22,5.492a6.01,6.01,0,0,0-.247-1.652,1.71,1.71,0,0,0-1.184-1.068,8.631,8.631,0,0,0-2.64-.393,4.845,4.845,0,0,0-2.6.569,3.26,3.26,0,0,0-1.234,2.554,17.692,17.692,0,0,0,.01,4.731c.227,1.269.7,1.743,1.486,2.02a7,7,0,0,0,2.761.272,4.128,4.128,0,0,0,2.03-.69,3.146,3.146,0,0,0,1.073-1.33,6.063,6.063,0,0,0,.428-1.824c.075-.705.106-1.521.121-2.03s.015-.71-.005-1.159Z"
                    transform="translate(-1661.386 -670.064)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_1636"
                    data-name="Path 1636"
                    d="M2766.061,766.45c.428,0,.856,0,1.284,0,1.375,0,4.033,0,5.587,0a8.425,8.425,0,0,1,2.858.272,7.41,7.41,0,0,1,3.115,1.764,7.2,7.2,0,0,1,1.907,4.242,13.354,13.354,0,0,1-.534,5.16,4.451,4.451,0,0,1-1.943,2.731,9.573,9.573,0,0,1-4.242,1.209,54.188,54.188,0,0,1-6.11.135c-.175-.005-.327-.011-.459-.019v6.191a1.41,1.41,0,0,1-1.406,1.406h-1.93a1.41,1.41,0,0,1-1.406-1.406V769.73a3.29,3.29,0,0,1,3.28-3.28Zm1.461,4.946v6.091l.208,0c.873-.013,2.559-.013,3.95-.005a6.9,6.9,0,0,0,3.212-.394,2.88,2.88,0,0,0,1.2-2.284,4.277,4.277,0,0,0-.513-2.916,3.685,3.685,0,0,0-2.782-1.074,24.253,24.253,0,0,0-3.735-.006c-.885.08-1.079.216-1.221.327-.122.1-.206.173-.322.266Z"
                    transform="translate(-2701.854 -748.205)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_1637"
                    data-name="Path 1637"
                    d="M3609.81,18.146h22.751l4.837,13.5L3648.993,0h6.448l-15.092,41.343H3634.7L3628,22.777h-6.13V41.431h-5.912V22.821h-6.086Z"
                    transform="translate(-3528.722)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className={styles["footer-section-col-2"]}>
        <ul className={styles["footer-menu"]}>
          <li onClick={() => setTargetEl("login")}>
            <Link to="/">Login</Link>
          </li>
          <li onClick={() => setTargetEl("activation")}>
            <Link to="/payment" onClick={() => setTargetEl("activation")}>
              Activation
            </Link>
          </li>
          <li onClick={() => setTargetEl("registration")}>
            <Link to="/">Registration</Link>
          </li>
          <li onClick={() => setTargetEl("reseller")}>
            <Link to="/">Become a reseller</Link>
          </li>
          {localStorage.getItem("hasNews") === "true" && (
            <li onClick={() => setTargetEl("news")}>
              <Link to="/">News</Link>
            </li>
          )}

          <li onClick={() => setTargetEl("help")}>
            <Link to="/">Help</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
