import React, { useState, useEffect } from "react";
import classNames from "classnames";

import ICONS from "../../../config/icons";
import IMAGES from "../../../config/images";

import styles from "./header.module.scss";
import { Link } from "react-router-dom";

export default function Sidebar({ open, setOpen }) {
  const [targetEl, setTargetEl] = useState(null);

  const onClick = (elem) => {
    setTargetEl(elem);
    setOpen(false);
  };

  useEffect(() => {
    let id;
    if (window.location.pathname == "/" && targetEl) {
      id = setTimeout(() => {
        document.getElementById(targetEl)?.scrollIntoView({
          behavior: "smooth",
        });
        setTargetEl(null);
      }, 50);
    }
    return () => clearTimeout(id);
  }, [targetEl]);

  return (
    <div
      className={classNames(styles["sidebar-drawer-wrapper"], {
        [styles["show"]]: open,
      })}
    >
      <div className={styles["empty"]} onClick={() => setOpen(false)}></div>
      <div
        className={classNames(styles["sidebar-drawer"], {
          [styles["open"]]: open,
        })}
      >
        <div className={styles["sidebar-drawer-title"]}>
          <Link to="/" onClick={() => onClick("login")}>
            <div className={styles["sidebar-logo-wrapper"]}>
              <img src={IMAGES.LOGO} alt="" />
            </div>
          </Link>
          <div
            onClick={() => setOpen(false)}
            className={styles["sidebar-close-icon"]}
          >
            {ICONS.CLOSE_ICON}
          </div>
        </div>
        <div className={styles["sidebar-drawer-content"]}>
          <ul className={styles["sidebar-drawer-content__menu"]}>
            <li
              className={styles["sidebar-drawer-content__menu-item"]}
              onClick={() => onClick("login")}
            >
              <Link to="/">Login</Link>
            </li>
            <li
              className={styles["sidebar-drawer-content__menu-item"]}
              onClick={() => onClick("activation")}
            >
              <Link to="/payment">Activation</Link>
            </li>
            <li
              className={styles["sidebar-drawer-content__menu-item"]}
              onClick={() => onClick("registration")}
            >
              <Link to="/">Registration</Link>
            </li>
            <li
              className={styles["sidebar-drawer-content__menu-item"]}
              onClick={() => onClick("reseller")}
            >
              <Link to="/">Become a reseller</Link>
            </li>
            {localStorage.getItem("hasNews") === "true" && (
              <li
                className={styles["sidebar-drawer-content__menu-item"]}
                onClick={() => onClick("news")}
              >
                <Link to="/">News</Link>
              </li>
            )}

            <li
              className={styles["sidebar-drawer-content__menu-item"]}
              onClick={() => onClick("help")}
            >
              <Link to="/">Help</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
