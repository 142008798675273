import React from "react";

import styles from "../../components/components.module.scss";

export default function PrivacyPolicy() {
  return (
    <div className={styles["terms-wrapper"]}>
      <div className={styles["terms-holder"]}>
        <h1 className={styles["privacy-title"]}>PRIVACY POLICY</h1>
        <div
          style={{
            fontSize: "20px",
          }}
        >
          SHEMS CONSEILS SARL built the{" "}
          <span className={styles["i-red"]}>IREDAPP</span> Player app as a
          Commercial app. This SERVICE is provided by SHEMS CONSEILS and is
          intended for use as is. This page is used to inform visitors regarding
          our policies with the collection, use, and disclosure of Personal
          Information if anyone decided to use our Service. If you choose to use
          our Service, then you agree to the collection and use of information
          in relation to this policy. The Personal Information that we collect
          is used for providing and improving the Service. We will not use or
          share your information with anyone except as described in this Privacy
          Policy. The terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, which is accessible at
           <a href="#/" className={styles["terms-link"]}>
            https://iredtv.net
          </a>
          unless otherwise defined in this Privacy Policy. Information
          Collection and Use For a better experience, while using our Service,
          we may require you to provide us with certain personally identifiable
          information, including but not limited to SmartTV MAC address, email,
          first name, last name, address, phone number. The information that we
          request will be retained by us and used as described in this privacy
          policy. The app does use third party services that may collect
          information used to identify you. Log Data We want to inform you that
          whenever you use our Service, in a case of an error in the app we
          collect data and information (through third party products) on your
          SmartTV called Log Data. This Log Data may include information such as
          your device Internet Protocol IP address, MAC address, device name,
          operating system version, the configuration of the app when utilizing
          our Service, the time and date of your use of the Service, and other
          statistics. Cookies Cookies are files with a small amount of data that
          are commonly used as anonymous unique identifiers. These are sent to
          your browser from the websites that you visit and are stored on your
          device's internal memory. This Service does not use these cookies
          explicitly. However, the app may use third party code and libraries
          that use cookies to collect information and improve their services.
          You have the option to either accept or refuse these cookies and know
          when a cookie is being sent to your device. If you choose to refuse
          our cookies, you may not be able to use some portions of this Service.
          Service Providers We may employ third-party companies and individuals
          due to the following reasons:
          <ul className={styles["privacy-list"]}>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose. Security We
          value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security. Links to Other Sites This
          Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services. Changes to This
          Privacy Policy We may update our Privacy Policy from time to time.
          Thus, you are advised to review this page periodically for any
          changes. We will notify you of any changes by posting the new Privacy
          Policy on this page. These changes are effective immediately after
          they are posted on this page. Contact Us If you have any questions or
          suggestions about our Privacy Policy, do not hesitate to contact us at
          <p>
            <span>
              <a
                href="mailto:support@iredtv.net"
                className={styles["terms-link"]}
              >
                support@iredtv.net
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
