import React, { useState } from "react";

import ICONS from "../../../config/icons";
import IMAGES from "../../../config/images";

import ButtonComponent from "../../../components/ButtonComponent";
import Playlist from "./Playlist";
import AddPlaylist from "./AddPlaylist";

import styles from "../devices.module.scss";

export default function Device({ device, getDevices }) {
  const [openAddPlaylistModal, setOpenAddPlaylistModal] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddPlaylist
        open={openAddPlaylistModal}
        setOpen={setOpenAddPlaylistModal}
        mac={device.mac}
        getDevices={getDevices}
      />
      <div className={styles["single-devices-container"]}>
        <div className={styles["device-desc-container"]}>
          <div className={styles["device-desc"]}>
            <img
              src={device.model == "tizen" ? IMAGES.TIZEN : IMAGES.WEBOS}
              alt=""
              width={129}
              height={129}
            />
            <div>
              <p className={styles["single-device"]}>Name : {device.model}</p>
              <p className={styles["single-device"]}>
                Playlist : {device.playlists?.length}
              </p>
              <p className={styles["single-device"]}>Mac : {device.mac}</p>
            </div>
          </div>
          <div className={styles.arrow} onClick={() => setOpen(!open)}>
            {open ? ICONS.ARROW_UP : ICONS.ARROW_DOWN}
          </div>
        </div>
        <div className={open ? styles.visible : styles.hide}>
          <h5 className={styles["playlists"]}>Playlists</h5>

          <div className={styles["content"]}>
           {! device.playlists.length == 0 &&
            <div className={styles["content-playlist__title"]}>
              <div className={styles["content-playlist__title-name"]}>Name</div>
              <div className={styles["content-playlist__title-url"]}>URL</div>
            </div>}

            {device.playlists?.map((playlist) => (
              <Playlist
                playlist={playlist}
                getDevices={getDevices}
                deviceId={device.id}
              />
            ))}
            <div className={styles["add-icon-container"]}>
              <ButtonComponent
                onClick={() => setOpenAddPlaylistModal(true)}
                text={ICONS.ADD}
                styles={{
                  padding: "9px 41px !important",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
