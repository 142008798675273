import React, { useEffect, useState } from "react";
import REQUESTS from "../../api/requests";
import { useNavigate, useParams } from "react-router-dom";
import NewsItem from "../home/news/components/NewsItem";

import ICONS from "../../config/icons";
import IMAGES from "../../config/images";
import { parseDate } from "../../config/utils";
import styles from "./newsPage.module.scss";

export default function CurrentNews() {
  const [currentNews, setCurrentNews] = useState({});
  const [news, setNews] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getCurrentNews = () => {
    REQUESTS.NEWS.GET({ filter: id }).then((data) => {
      if (!data.error) {
        setCurrentNews(data.message.rows[0]);
      }
    });
  };

  const getNews = () => {
    REQUESTS.NEWS.GET_RELATED_NEWS({ ids: `[${id}]` }).then((data) => {
      if (data && !data.error) {
        setNews(data.message);
      }
    });
  };

  useEffect(() => {
    getCurrentNews();
    getNews();
  }, [id]);

  return (
    currentNews && (
      <div className={styles["current-news-page"]}>
        <div>
          <div className={styles["current-news-page__top"]}>
            <div
              className={styles["current-news-page__back-btn"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              {ICONS.ARROW_BACK}
            </div>

            <div className={styles["current-news-page__current-news"]}>
              <div>
                <img
                  src={currentNews.image}
                  alt=""
                  onError={(e) => (e.target.src = IMAGES.ERR_IMG)}
                  className={styles["current-news-page__current-news__img"]}
                />
              </div>
              <div>
                <p className={styles["current-news-page__current-news__title"]}>
                  {currentNews.title}
                </p>
                <p className={styles["current-news-page__current-news__createdAt"]}> Added on: {parseDate( currentNews.createdAt)}</p>
                <span
                  className={
                    styles["current-news-page__current-news__description"]
                  }
                >
                  {currentNews.description}
                </span>
              </div>
            </div>
          </div>

          <div className={styles["current-news-page__similar-news"]}>
            <p className={styles["title"]}>Similar news</p>
            <div className={styles["current-news-page__bottom"]}>
              {news && news.splice(0,4).map((item) => <NewsItem {...item} key={item.id} />)}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
