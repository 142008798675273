import { HOST } from "./config";
import { API } from "./config";
import { API_V2 } from "./config";

const URLS = {
  // a
  ACTIVATION_COUPON: `${API_V2}coupon_activation`,
  // b
  // c
  CONFIRM_LOGIN: `${API_V2}confirm_login_by_mac`,
  CONFIRM_DEVICE_CODE: `${API}confirm_device`,
  CONFIRM_RESET_PASSWORD:`${API_V2}confirm_reset_password`,
  // d
  DEVICES: `${API}devices`,
  DEVICE: `${API}device`,

  // e
  // f
  // g

  // h
  // i
  INFO: `${API}app_info`,
  IP:`${API}getIp`,
  // j
  // k
  // l
  LOGIN_BY_MAC: `${API_V2}login_by_mac`,
  LOGIN_BY_EMAIL: `${API_V2}login`,
  // m
  // n
  NEWS: `${API_V2}news`,
  NEWS_NOT_IN: `${API_V2}news_notIn`,
  // o
  // p 
  PLAYLIST: `${API_V2}playlist`,
  PARSE_PLAYLIST: `${API_V2}parse_playlist`,
  PAYPAL: `${API}paypal/payment`,
  PAY: `${API}payment`,
  // q
  // r
  REGISTER: `${API_V2}register`,
  RESSELLER_REGISTRATION: `${HOST}/resseler/register`,
  REFFERAL_ACTIVATION: `${HOST}resseler/activate_by_link`,
  RESET_PASSWORD:`${API_V2}reset_password`,
  // s
  // t
  // u
  // v
  VOUCHER_CODE_ACTIVATION: `${API_V2}voucher_code_activation`,
  VALIDATE: `${API_V2}validate`,
  VALIDATE_MAC: `${API}validate_mac`,
  // w
  // x
  // y
  // z
};

export default URLS;
