import LOGO from "../assets/images/logo.png";
import TIZEN from "../assets/images/Path.svg";
import WEBOS from "../assets/images/lg.png";
import VISAMASTER from "../assets/images/visamaster.png";
import CMI from "../assets/images/cmi.jpeg";
import ERR_IMG from "../assets/images/err.png";

const IMAGES = {
  // a
  // b
  // c
  CMI,
  // d
  // e
  ERR_IMG,
  // f
  // g
  // h
  // i
  // j
  // k
  // l
  LOGO,
  // m
  // n
  // o
  // p
  // q
  // r
  // s
  // t
  TIZEN,
  // u
  // v
  VISAMASTER,
  // w
  WEBOS,
  // x
  // y
  // z
};

export default IMAGES;
