import React from "react";
import styles from "./components.module.scss";

export default function ButtonComponent({ text, ...props }) {
  return (
    <button className={styles["btn"]} {...props}>
      {text}
    </button>
  );
}
