import classNames from "classnames";

import styles from "./components.module.scss";

export default function MessageComponent({
  msgtype = "error",
  message = null,
  style,
}) {
  return (
    <div
      className={classNames([
        styles["message"],
        { open:!message},
      ])}
      style={style}
    >
      {msgtype === "success" ? (
        <div className={styles["success"]}>{message}</div>
      ) : (
        <div className={styles["danger"]}>{message}</div>
      )}
    </div>
  );
}
