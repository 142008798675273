import React from "react";
import ReactDOM from "react-dom";

import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../api/requests";
import ButtonComponent from "../../components/ButtonComponent";
import MessageComponent from "../../components/MessageComponent";

import styles from "./styles.module.scss";
import { useEffect } from "react";

export default function ResetPassword({ email }) {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    code: Yup.string().required("Code is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password is too short."),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        code: "",
        password: "",
      },
      onSubmit: (values) => {
        confirmResetPassword();
      },
      validationSchema,
    });
  const confirmResetPassword = () => {
    REQUESTS.CONFIRM_RESET_PASSWORD({ ...values, email }).then((data) => {
      if (data.error) {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className:styles['toast']
        });
      } else {
        toast.success("Password reset done", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
    });
  };

  return (
    <>
      {
        ReactDOM.createPortal( <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />,document.body)
      }
       
      <div className={styles["reset-pass-container"]}>
        <p className={styles["reset-pass-container__title"]}>
          Please check you email (
          <span className={styles["email"]}>{email}</span>). We have sent
          confirmation code
        </p>
        <form onSubmit={handleSubmit} className="reset-pass-container__form">
          <input
            type="text"
            name="code"
            placeholder="Enter confirm code from email"
            className={styles["reset-pass-container__form__inp"]}
            value={values.code}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.code && touched.code ? errors.code : null}
          />
          <input
            type="password"
            name="password"
            placeholder="Enter new password"
            className={styles["reset-pass-container__form__inp"]}
            value={values.password}
            onChange={handleChange}
          />
          <MessageComponent
            message={
              errors.password && touched.password ? errors.password : null
            }
          />
          <div className={styles["form-bottom"]}>
            <button type="submit" className={styles['btn']}> Reset password</button>
          </div>
        </form>
      </div>
    </>
  );
}
