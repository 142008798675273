import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../api/requests";
import Modal from "../../../components/Modal";
import ButtonComponent from "../../../components/ButtonComponent";
import MessageComponent from "../../../components/MessageComponent";

import styles from "../devices.module.scss";

export default function EditPlaylistModal({
  open,
  setOpen,
  id,
  playlistId,
  name,
  url,
  getDevices,
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    url: Yup.string()
      //   .matches(
      //     /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/){1}.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      //     "Enter a valid url"
      //   )
      .required("Required"),
  });

  const { values, touched, errors, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues: {
        name,
        url,
      },
      onSubmit: (values) => {
        editPlaylist();
      },
      validationSchema,
    });

  const editPlaylist = () => {
    const body = {
      id: playlistId,
      deviceId: id,
      ...values,
    };
   
    REQUESTS.PLAYLIST.EDIT(body).then((data) => {
      if (data.error) {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        getDevices();
        setOpen(false);
      }
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal open={open} setOpen={setOpen}>
        <p className={styles["edit-playlist-title"]}>Edit Device Player</p>
        <form onSubmit={handleSubmit} className={styles["edit-playlist-form"]}>
          <input
            type="text"
            className={styles["edit-playlist-form__inp"]}
            name="name"
            value={values.name}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.name && touched.name ? errors.name : null}
          />
          <input
            type="text"
            className={styles["edit-playlist-form__inp"]}
            name="url"
            value={values.url}
            onChange={handleChange}
          />
          <MessageComponent
            message={errors.url && touched.url ? errors.url : null}
          />
          <div className={styles["save-btn"]}>
            <ButtonComponent text="SAVE" type="submit" />
          </div>
        </form>
      </Modal>
    </div>
  );
}
