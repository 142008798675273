import React, { useState } from "react";

// import { Tooltip } from "react-tooltip";

import REQUESTS from "../../../api/requests";
import ICONS from "../../../config/icons";

import styles from "../devices.module.scss";
import EditPlaylistModal from "./EditPlaylistModal";

export default function Playlist({ playlist, getDevices, deviceId }) {
  const [open, setOpen] = useState(false);

  const deletePlaylist = () => {
    REQUESTS.PLAYLIST.DELETE({ id: playlist.id }).then((data) => {
      if (!data.error) {
        getDevices();
      }
    });
  };

  return (
    <>
      <div className={styles["content-playlist__row"]}>
        <EditPlaylistModal
          open={open}
          setOpen={setOpen}
          getDevices={getDevices}
          id={deviceId}
          playlistId={playlist.id}
          name={playlist.name}
          url={playlist.url}
        />
        <div className={styles["content-playlist__name-url"]}>
          <div
            className={styles["content-playlist__name"]}
            // id={`${playlist.id}name`}
            // data-tooltip-content={playlist.name}
          >
            {playlist.name}
          </div>
          <div className={styles["divider"]}></div>
          <div
            className={styles["content-playlist__url"]}
            // id={`${playlist.id}`}
            // data-tooltip-content={playlist.url}
          >
            {playlist.url}
          </div>
          
          <div className={styles["content-playlist__edit-delete"]}>
            <div
              className={styles["content-playlist__edit"]}
              onClick={() => setOpen(true)}
            >
              {ICONS.EDIT}
            </div>
            <div
              className={styles["content-playlist__delete"]}
              onClick={deletePlaylist}
            >
              {ICONS.DELETE}
            </div>
          </div>
        </div>
      </div>
      {/* <ReactTooltip anchorId="my-element" /> */}
      {/* <Tooltip anchorId={`${playlist.id}name`} /> */}
      {/* <Tooltip anchorId={`${playlist.id}`} /> */}
    </>
  );
}
