import React from "react";

import ICONS from "../../../config/icons";
import ResellerForm from "./components/ResellerForm";

import styles from "./reseller.module.scss";

export default function Reseller() {
  return (
    <div className={styles["reseller-section"]} id="reseller">
      <div className={styles["holder"]}>
        <div className={styles["reseller-section__content"]}>
          <h1 className={styles["reseller-section__content-title"]}>
            BECOME A RESELLER
          </h1>
          <div className={styles["reseller-section__content__wrapper"]}>
            <div className={styles["left-block"]}>
              <h4 className={styles["left-block__title"]}>
                Let’s work together
              </h4>
              <span className={styles["left-block__text"]}>
                A flexible, value-based program provides you with competitive
                prices that will help you sell our app and make gains you want.
              </span>
              <h4 className={styles["left-block__title"]}>Partnership goals</h4>
              <span className={styles["left-block__text"]}>
                Joining our program means being part of our Team. We will take
                care of all your business needs in terms of support for the app
                as we fully understand that It takes more than a great product
                to grow your business—which means we rely on our partners just
                as much as they rely on us. Whether you’re looking to create new
                revenue opportunities with existing clients, acquire new
                customers by combining our app with your playlist sale for an
                all in one package, or all of the above, we’ve got the tools you
                need to make it happen.
              </span>
              <h4 className={styles["left-block__title"]}>
                Find the right fit for you
              </h4>
              <span className={styles["left-block__text"]}>
                No two businesses are the same, which is why our program is
                based on capabilities and contributions. Get your business in
                shape by choosing the option that works for you—we’ll spot you
                from there.
              </span>
            </div>

            <ResellerForm />

            <div className={styles["right-block"]}>
              <h4 className={styles["right-block__title"]}>Reseller program</h4>
              <span className={styles["right-block__text"]}>
                You will be able to purchase the app at a competitive price
                which will go lower as you advance in tears and are able to sell
                it at the price that you want. You also benefit from support to
                your customers and have full control over the devices you
                activate.
              </span>
              <h4 className={styles["right-block__title"]}>
                Affiliate program
              </h4>
              <span className={styles["right-block__text"]}>
                Everything you need to get up and running without an initial
                investment. With this program we would like to give the
                opportunity for our partners to start with no initial
                investment. You’ll be able to generate links that you could set
                for different acquisition platforms and track your stats as well
                have a revenue stream just by focusing on marketing.
              </span>
              <div
                className={styles["reseller-panel-link"]}
                onClick={() =>
                  window.location.replace(
                    "https://reseller.iredtv.net"
                  )
                }
              >
                Reseller Panel {ICONS.RESELLER_PANEL_ICON}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
