import React, { useState } from "react";
import classNames from "classnames";

import REQUESTS from "../../../api/requests";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ButtonComponent from "../../../components/ButtonComponent";
import Modal from "../../../components/Modal";

import styles from "../devices.module.scss";

export default function AddPlaylist({ open, setOpen, mac, getDevices }) {
  const [type, setType] = useState("url");
  const [values, setValues] = useState({
    name: "",
    url: "",
    file: null,
  });

  const addPlaylist = (e) => {
    e.preventDefault();
    if (type === "file") {
      if (values.file) {
        let formData = new FormData();
        let type =
          values.file.name.split(".")[values.file.name.split(".").length - 1];

        if (type === "m3u8" || type === "m3u") {
          formData.append("file", values.file);
          formData.append("isFromFile", 1);
          formData.append("name", values.name);
          formData.append("mac", mac);
          REQUESTS.PLAYLIST.PARSE_PLAYLIST(formData).then((data) => {
            if (data.error) {
              toast.error(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              getDevices();
              setOpen(false);
              setValues({
                name: "",
                url: "",
                file: "",
              });
            }
          });
        } else {
          toast.error("Invalid file. Use only (m3u,m3u8) files", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } 
    } else {
      if (values.name && values.url) {
        REQUESTS.PLAYLIST.ADD({ mac, name: values.name, url: values.url }).then(
          (res) => {
            if (res.error) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.success(res.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setOpen(false);
              setValues({
                name: "",
                url: "",
                file: "",
              });
              getDevices();
            }
          }
        );
      }
    }
  };
  const handleChange = (e) => {
    const name = e.target.name;

    if (name == "file") {
      setValues({
        ...values,
        file: e.target.files[0],
      });
    } else {
      setValues({
        ...values,
        [name]: e.target.value,
      });
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal open={open} setOpen={setOpen}>
        <div className={styles["add-playlist-modal-content"]}>
          <p className={styles["add-playlist-modal-title"]}>Add new playlist</p>
          <form className={styles["add-playlist-form"]} onSubmit={addPlaylist}>
            <input
              type="text"
              name="name"
              placeholder="Enter playlist name"
              className={styles["add-playlist-inp"]}
              value={values.name}
              onChange={handleChange}
            />

            <div className={styles["add-playlist-tabs"]}>
              <div
                onClick={() => setType("url")}
                className={classNames(styles["add-playlist-tabs__url"], {
                  [styles["active"]]: type === "url",
                })}
              >
                URL
              </div>
              <div
                onClick={() => setType("file")}
                className={classNames(styles["add-playlist-tabs__file"], {
                  [styles["active"]]: type === "file",
                })}
              >
                FILE
              </div>
            </div>
            {type === "url" ? (
              <>
                <input
                  type="text"
                  name="url"
                  placeholder="Enter playlist url"
                  className={styles["add-playlist-inp"]}
                  value={values.url}
                  onChange={handleChange}
                />
              </>
            ) : (
              <input
                type="file"
                accept="m3u8,m3u"
                className={styles["file-inp"]}
                name="file"
                onChange={handleChange}
              />
            )}

            <div className={styles["add-playlist-btn"]}>
              <ButtonComponent text="ADD" type="submit" />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
