import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ButtonComponent from "../../../components/ButtonComponent";
import MessageComponent from "../../../components/MessageComponent";

import REQUESTS from "../../../api/requests";

import styles from "./registration.module.scss";

export default function Registration() {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password is too short."),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: (values) => {
        registration();
      },
      validationSchema,
    });

  const registration = () => {
    REQUESTS.REGISTRATION(values).then((data) => {
      if (!data.error) {
        localStorage.setItem("token", data.message);
        document.getElementById("login").scrollIntoView({
          behavior: "smooth",
        });
        resetForm();
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  return (
    <div className={styles["registration-page"]} id="registration">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={styles["holder"]}>
        <div className={styles["registration-wrapper"]}>
          <h1 className={styles["registration-page__title"]}>Registration</h1>
          <div className={styles["registration-wrapper-content"]}>
            <form
              onSubmit={handleSubmit}
              className={styles["registration-form"]}
            >
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                className={styles["registration-form__inp"]}
                value={values.email}
                onChange={handleChange}
              />
              <MessageComponent
                message={errors.email && touched.email ? errors.email : null}
              />
              <input
                type="password"
                name="password"
                placeholder="Enter new password"
                className={styles["registration-form__inp"]}
                value={values.password}
                onChange={handleChange}
              />
              <MessageComponent
                message={
                  errors.password && touched.password ? errors.password : null
                }
              />
              <div className={styles["registration-btn"]}>
                <ButtonComponent
                  type="submit"
                  style={{ margin: "24px 0 67px" }}
                  text="Registration"
                />
              </div>
            </form>
            <div className={styles["desc"]}>
              <span className={styles["desc-txt"]}>
                Create account for managing multiple <br /> devices
              </span>
              <ol className={styles["desc-list"]}>
                <li>Enter your email address</li>
                <li>Enter your password </li>
                <li>Hit register</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
