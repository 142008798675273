import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

import REQUESTS from "../../../api/requests";
import IMAGES from "../../../config/images";
import { validateMac } from "../../../config/validation";
import ButtonComponent from "../../../components/ButtonComponent";
import MessageComponent from "../../../components/MessageComponent";
import Paypal from "./Paypal";

import styles from "../paymentPage.module.scss";

export default function DirectPayment({ price }) {
  const [message, setMessage] = useState(null);
  const [payWithPaypall, setPayWithPaypall] = useState(true);
  const [checkboxes, setCheckboxes] = useState({
    all: false,
    privacy: false,
    termsOfUse: false,
    termsOfSales: false,
  });

  const [countryIp, setCountryIp] = useState(null);
  let [searchParams] = useSearchParams();
  const ref = useRef();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mac: Yup.string().required("Mac is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        email: "",
        mac: searchParams.get("activation_mac") || "",
      },
      onSubmit: (values) => {
        pay();
      },
      validationSchema,
    });

  const pay = () => {
    const body = {
      mac: values.mac,
      email: values.email || "no email address",
      BillTocompany: "IredIptv",
      BillToName: values.mac,
      callbackUrl: encodeURI("https://iredtv.net:5000/api/callback"),
      clientId: "600002144",
      currency: "504",
      failUrl: "https://iredtv.net:5000/api/payment_error",
      hashAlgorithm: "ver3",
      lang: "en",
      okurl: "https://iredtv.net:5000/api/payment_success",
      rnd: new Date().getTime() / 1000,
      storetype: "3d_pay_hosting",
      TranType: "PreAuth",
    };

    if (values.mac && values.email) {
      REQUESTS.PAYMENT(body).then((data) => {
        for (let key in data.data) {
          let input = document.createElement("input");
          input.type = "hidden";
          input.value = data.data[key];
          input.name = key;
          ref.current.appendChild(input);
        }
        ref.current.submit();
      });
    }
  };

  const getIp = () => {
    REQUESTS.GET_API().then((data) => {
      if (!data.error) {
        setCountryIp(data.message);
      }
    });
  };

  useEffect(() => {
    let timeoutId;

    if (values.mac.trim()) {
      timeoutId = setTimeout(() => {
        validateMac(values.mac, setMessage);
      }, [500]);
      
    } else {
      setMessage(null);
    }
    return () => clearTimeout(timeoutId);
  }, [values.mac]);

  useEffect(() => {
    if (checkboxes.all) {
      setCheckboxes({
        ...checkboxes,
        privacy: true,
        termsOfUse: true,
        termsOfSales: true,
      });
    } else if (!checkboxes.all) {
      setCheckboxes({
        ...checkboxes,
        privacy: false,
        termsOfUse: false,
        termsOfSales: false,
      });
    }
  }, [checkboxes.all]);

  useEffect(() => {
    if (
      checkboxes.privacy &&
      checkboxes.termsOfSales &&
      checkboxes.termsOfUse
    ) {
      setCheckboxes({
        ...checkboxes,
        all: true,
      });
    } else if (
      checkboxes.all &&
      !checkboxes.privacy &&
      checkboxes.termsOfSales &&
      checkboxes.termsOfUse
    ) {
      setCheckboxes({
        ...checkboxes,
        all: false,
        privacy: false,
        termsOfUse: true,
        termsOfSales: true,
      });
    }
  }, [checkboxes.privacy, checkboxes.termsOfSales, checkboxes.termsOfUse]);

  useEffect(() => {
    if (countryIp) {
      let url = "https://ipapi.co/" + countryIp + "/json";
      axios
        .get(url)
        .then((data) => {
          let countryCode = "MA";
          let ipCountryCode = data.data.country_code;
          if (ipCountryCode === countryCode) {
            setPayWithPaypall(false);
          } else {
            setPayWithPaypall(true);
          }
        })
        .catch((err) => {
          setPayWithPaypall(false);
        });
    }
  }, [countryIp]);

  useEffect(() => {
    getIp();

    setTimeout(() => {
      let activationSection = document.getElementById("activation");

      if (searchParams.get("activation_mac") && activationSection) {
        activationSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, []);


  return (
    <div className={styles["activation"]}>
      <form className={styles["activation-form"]} onSubmit={handleSubmit}>
        <input
          name="email"
          type="email"
          placeholder="Enter your email address"
          className={styles["activation-form__inp"]}
          value={values.email}
          onChange={handleChange}
        />
        <MessageComponent
          message={errors.email && touched.email ? errors.email : null}
        />
        <input
          name="mac"
          type="text"
          placeholder="Enter your MAC address"
          className={styles["activation-form__inp"]}
          value={values.mac}
          onChange={handleChange}
        />
        <MessageComponent
          message={errors.mac && touched.mac ? errors.mac : message}
          msgtype={message === "Mac address is valid" ? "success" : "error"}
        />
        <div style={{ minHeight: 80, marginTop: 30 }}>
          {payWithPaypall ? (
            checkboxes.all &&
            message === "Mac address is valid" && (
              <Paypal
                price={price}
                mac={values.mac}
                email={values.email}
                resetForm={resetForm}
                setCheckboxes={setCheckboxes}
              />
            )
          ) : (
            <div
              className={styles["cmi-container"]}
              style={{ justifyContent: !checkboxes.all && "flex-end" }}
            >
              {checkboxes.all && (
                <div className={styles["cmi-container-vimamaster"]}>
                  <img src={IMAGES.CMI} alt="cmi" width={45} />
                  <img
                    src={IMAGES.VISAMASTER}
                    alt="visa master card"
                    width={110}
                  />
                </div>
              )}
              <ButtonComponent
                text="Pay"
                disabled={!checkboxes.all}
                onClick={pay}
                type="submit"
                style={{ background: checkboxes.all ? "#F01D23" : "#fd9598" }}
              />
            </div>
          )}
        </div>
      </form>

      <div className={styles["warning"]}>
        <span>
          You must agree to our privacy policy and terms of use before
          activation
        </span>
        <div className={styles["checkboxes-group"]}>
          <div className={styles["checkbox"]}>
            <input
              type="checkbox"
              id="greeWithall"
              className={styles["check-btn"]}
              checked={checkboxes.all}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, all: e.target.checked })
              }
            />
            <label htmlFor="greeWithall" className={styles["agree-with-all"]}>
              Agree with all
            </label>
          </div>
          <div className={styles["checkbox"]}>
            <input
              type="checkbox"
              id="privacy"
              className={styles["check-btn"]}
              checked={checkboxes.privacy}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, privacy: e.target.checked })
              }
            />
            <label htmlFor="privacy">
              <Link to="/privacy">Privacy policy</Link>
            </label>
          </div>
          <div className={styles["checkbox"]}>
            <input
              type="checkbox"
              id="terms"
              className={styles["check-btn"]}
              checked={checkboxes.termsOfUse}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, termsOfUse: e.target.checked })
              }
            />
            <label htmlFor="terms">
              <Link to="/termsofuse">Terms of use</Link>
            </label>
          </div>
          <div className={styles["checkbox"]}>
            <input
              type="checkbox"
              id="sales"
              className={styles["check-btn"]}
              checked={checkboxes.termsOfSales}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, termsOfSales: e.target.checked })
              }
            />
            <label htmlFor="sales">
              <Link to="/termsofsales">Terms of sales</Link>
            </label>
          </div>
        </div>
      </div>
      {/* hidden form for cmi payment */}
      <form
        id={"form"}
        ref={ref}
        method={"post"}
        action="https://payment.cmi.co.ma/fim/est3Dgate"
      ></form>
    </div>
  );
}
