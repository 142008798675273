import React, { useEffect, useState ,useRef} from "react";
import REQUESTS from "../../api/requests";
import NewsItem from "../home/news/components/NewsItem";

import styles from "./newsPage.module.scss";

export default function NewsPage() {
  const [news, setNews] = useState([]);
  const [count, setCount] = useState(10);
  const [limit, setLimit] = useState(10);
  
  const observer = useRef();

  const getNews = () => {
    REQUESTS.NEWS.GET({
      limit,
    }).then((data) => {
      if (!data.error) {
        if (data.message && data.message.rows) {
          setNews(data.message.rows);
          setCount(data.message.count);
        }
      }
    });
  };

  useEffect(() => {
    if (count > limit || count === limit) {
      getNews();
    }
  }, [limit]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setLimit((limit) => limit + 1);
        }
      },
      { threshold: 1 }
    );
  }, []);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current.observe(document.querySelector(".bottom"));
  }, [news]);


  return (
    <div className={styles["news-page"]}>
      <div className={styles["news-page__wrapper"]}>
        {news.map((item) => (
          <NewsItem {...item} />
        ))}
        <div className="bottom"></div>
      </div>
    </div>
  );
}
