import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import REQUESTS from "../../../api/requests";
import styles from "./news.module.scss";
import ButtonComponent from "../../../components/ButtonComponent";
import NewsItem from "./components/NewsItem";

export default function News() {
  const [news, setNews] = useState([]);

  const navigate = useNavigate();

  const getNews = () => {
    const params = {
      pagination: 1,
      limit: 3,
      page: 1,
    };

    REQUESTS.NEWS.GET(params).then((data) => {
      if (!data.error) {
        if (data.message && data.message.rows) {
          setNews(data.message.rows);
          if (data.message.rows.length > 0) {
            localStorage.setItem('hasNews',true)
          } else {
            localStorage.setItem('hasNews',false)
          }
        } 
      }
    });
  };

  const handleClick = () => {
    navigate("/news");
    window.scrollTo(0, 0);
  };
  
  useEffect(() => {
    getNews();
  }, []);

  return (
    news.length > 0 && (
      <div id="news" className={styles["news-section"]}>
        <div className={styles["news-section__wrapper"]}>
          <h2 className={styles["title"]}>News</h2>
          <div className={styles["news-wrapper"]}>
            {news.map((item) => (
              <NewsItem {...item} key={item.id} />
            ))}
          </div>
          <div className={styles["news-section-see-more-btn"]}>
            <ButtonComponent text={"See More News"} onClick={handleClick} />
          </div>
        </div>
      </div>
    )
  );
}
