const PATHS = {
  // a
  ACTIVATION: "/activation",
  // b
  // c
  // d
  DEVICES: "/devices",
  // e
  // f
  // g
  // h
  HELP: "/help",
  // i
  // j
  // k
  // l
  // m
  // n
  // o
  // p
  PAYMENT:"/payment",
  PRIVACY: "/privacy",
  PAYMENT_ERROR: "/fail",
  PAYMENT_SUCCESS: "/ok",
  // q
  // r
  REGISTRATION: "/registration",
  RESELLER: "/reseller",
  RESET_PASSWORD:"/reset_password",
  // s
  // t
  TERMS_OF_USE: "/termsofuse",
  TERMS_OF_SALES: "/termsofsales",
  // u
  // v
  // w
  // x
  // y
  // z
};

export default PATHS;
