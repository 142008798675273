import axios from "axios";

const request = async (method, url, body) => {
  let myAxios = axios.create();

  myAxios.defaults.headers.common["Authorization"] = localStorage.getItem(
    `Bearer ${localStorage.getItem("token")}`
  );

  const result = await myAxios[method](
    url,
    method === "delete" ? { data: body } : body
  );

  return result.data;
};

export default request;
