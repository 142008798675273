import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../../api/requests";

import MessageComponent from "../../../../components/MessageComponent";
import CodeModal from "./CodeModal";

import styles from "../login.module.scss";
import { useEffect } from "react";

export default function LoginByMac() {
  const [open, setOpen] = useState(false);
  let [searchParams] = useSearchParams();

  const validationSchema = Yup.object().shape({
    mac: Yup.string().required("Required"),
  });

  const { values, touched, errors, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues: {
        mac:searchParams.get("mac") || "" ,
      },
      onSubmit: (values) => {
        loginByMac();
      },
      validationSchema,
    });

  const loginByMac = () => {
    REQUESTS.LOGIN_BY_MAC(values).then((data) => {
      if (data.error) {
         toast.error(data.message, {
           position: "top-right",
           autoClose: 3000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
         });
      } else {
        setOpen(true);
      }
    });
  };

  useEffect(() => {
    const loginSection=document.getElementById('login')
    if (searchParams.get("mac") && loginSection) {
      loginSection.scrollIntoView({
        behavior: "smooth",
      })
    }
   }, [values.mac])

  return (
    <div className={styles["login-page__form-first"]}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <p className={styles["login-page__form__title"]}>
        Login by your MAC address
      </p>
      <form onSubmit={handleSubmit} className={styles["login-form-content"]}>
        <input
          type="text"
          name="mac"
          value={values.mac}
          onChange={handleChange}
          placeholder="Enter your MAC address"
          className={styles["login-page_input"]}
        />
        <MessageComponent
          message={
            errors.mac && touched.mac ? errors.mac : null
          }
        />
        <button type="submit" className={styles["login-page-btn"]}>
          Log in by MAC
        </button>
      </form>
      <CodeModal open={open} setOpen={setOpen} mac={values.mac} />
    </div>
  );
}
