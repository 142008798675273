import URLS from "./urls";
import request from "./request";

function buildGetQuery(object) {
  let query = "";
  for (let key in object) {
    query += key + "=" + object[key] + "&";
  }
  return "?" + query.substring(0, query.length - 1);
}

const REQUESTS = {
  // a
  ACTIVATE_BY_VOUCHER_CODE: (body) =>
    request("post", URLS.VOUCHER_CODE_ACTIVATION, body),
  ACTIVATIO_WITH_COUPON: (body) =>
    request("post", URLS.ACTIVATION_COUPON, body),
  ACTIVATION_REFERRAL_DEVICE: (body) => {
    return request("post", URLS.REFFERAL_ACTIVATION, body);
  },
  // b
  // c
  CONFIRM_LOGIN: (body) => request("post", URLS.CONFIRM_LOGIN, body),
  CONFIRM_DEVICE_CODE: (body) => {
    const TOKEN = localStorage.getItem("token");
    return request("post", `${URLS.CONFIRM_DEVICE_CODE}?token=${TOKEN}`, body);
  },
  CONFIRM_RESET_PASSWORD: (body) =>
    request("post", URLS.CONFIRM_RESET_PASSWORD, body),
  // d
  DEVICES: {
    GET: () => {
      const TOKEN = localStorage.getItem("token");
      return request("get", `${URLS.DEVICES}?token=${TOKEN}`, {});
    },
    ADD: (body) => {
      const TOKEN = localStorage.getItem("token");
      return request("post", `${URLS.DEVICE}?token=${TOKEN}`, body);
    },
  },
  // e
  // f
  // g
  GET_API: () => {
    return request("get", URLS.IP, {});
    // return request("get", 'http://192.168.8.128:8000/api/getIp', {});
  },
  GET_APP_INFO: () => {
    return request("get", URLS.INFO, {});
    // return request("get", 'http://192.168.8.128:8000/api/app_info', {});
  },
  GET_DEVICE: (body) => {
    const TOKEN = localStorage.getItem("token");
    return request("get", `${URLS.DEVICE}?token=${TOKEN}`, body);
  },
  // h
  // i
  // j
  // k
  // l
  LOGIN_BY_EMAIL: (body) => request("post", URLS.LOGIN_BY_EMAIL, body),
  LOGIN_BY_MAC: (body) => request("post", URLS.LOGIN_BY_MAC, body),
  // m
  // n
  NEWS: {
    GET: (params) => {
      // return request(
      //   "get",
      //   "http://192.168.8.128:8008/api/v2/news" + buildGetQuery(params),
      //   {}
      // );
      return request("get", URLS.NEWS + buildGetQuery(params), {});
    },
    GET_RELATED_NEWS: (params) => {
      // return request(
      //   "get",
      //   `http://192.168.8.128:8008/api/v2/news_notIn`+buildGetQuery(params),
      //   {}
      // )
      return request("get", URLS.NEWS_NOT_IN + buildGetQuery(params), {});
    },
  },
  // o
  // p
  PLAYLIST: {
    ADD: (body) => {
      const TOKEN = localStorage.getItem("token");
      return request("post", `${URLS.PLAYLIST}?token=${TOKEN}`, body);
    },

    PARSE_PLAYLIST: (body) => {
      const TOKEN = localStorage.getItem("token");
      return request("post", `${URLS.PARSE_PLAYLIST}?token=${TOKEN}`, body);
    },

    EDIT: (body) => {
      const TOKEN = localStorage.getItem("token");
      return request("put", `${URLS.PLAYLIST}?token=${TOKEN}`, body);
    },
    DELETE: (body) => {
      const TOKEN = localStorage.getItem("token");
      return request("delete", `${URLS.PLAYLIST}?token=${TOKEN}`, body);
    },
  },
  PAYPAL_PAYMENT: (body) => {
    return request("post", URLS.PAYPAL, body);
  },
  PAYMENT: (body) => {
    return request("post", URLS.PAY, body);
  },
  // q
  // r
  REGISTRATION: (body) => request("post", URLS.REGISTER, body),
  RESELLER_REGISTRATION: (body) =>
    request("post", URLS.RESSELLER_REGISTRATION, body),
  RESET_PASSWORD: (body) => {
    return request("post", URLS.RESET_PASSWORD, body);
  },
  // s
  // t
  // u
  // v
  VALIDATE: () => {
    const TOKEN = localStorage.getItem("token");
    return request("post", `${URLS.VALIDATE}?token=${TOKEN}`, {});
  },
  VALIDATE_MAC: (mac) => {
    const TOKEN = localStorage.getItem("token");

    // return request("get", `http://192.168.8.128:8008/api/validate_mac?token=${TOKEN}&mac=${mac}`, {});
    return request("get", `${URLS.VALIDATE_MAC}?token=${TOKEN}&mac=${mac}`, {});
  },
  // w
  // x
  // y
  // z
};

export default REQUESTS;
