import React from 'react'

import styles from "../../components/components.module.scss";
export default function TermsOfUse() {
  return (
    <div className={styles["terms-wrapper"]}>
      <div className={styles["terms-holder"]}>
        <h1 className={styles["privacy-title"]}>TERMS OF USE</h1>
        <div
          style={{
            fontSize: "20px",
          }}
        >
          The application <span className={styles["i-red"]}>IREDAPP</span>{" "}
          MediaPlayer is delivered without any content. <br />
          In addition, no content is provided from the application even after
          activating it on our website. <br />
          The information displayed at the beginning includes the{" "}
          <b className={styles["i-red-bold"]}>
            MAC
          </b>{" "}
          address as well as the website so that clients can activate the
          application once for life. The 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer app has a
          free 5-days trial period once installed on your smart TV to test the
          features of the app. The MAC address and the URL are essential for the
          proper functioning of the application.
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer does not
          provide any content or subscription. The content and subscriptions
          provided under the 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer app name
          are frauds. 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer disclaims
          any responsibility for the use of fraudulent content. The application 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer or it is
          entitled can not be held as responsible for the quality or
          interruptions of the content used on 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer,
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer only
          provides a media player. The application 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer does not
          provide any advertising for content providers or subscriptions and
          reserves the right to sue anyone who uses our name or logo for
          advertising purposes. Supported 
          <span className={styles["i-red"]}>IREDAPP</span> MediaPlayer Devices
          can be activated after one time purchace of 
          <b className={styles["i-red-bold"]}>110MAD</b> (
          <b className={styles["i-red-bold"]}>≈10.10 Euros</b>) once for
          lifetime
          <hr />
          <p className={styles["alert-danger"]}>
            After accepting the above conditions, no refund will be granted
            under any circumstances.
            <br />
            Note: If you are using a stolen card, your mac will be banned and
            its IP address will be reported.
          </p>
          <hr />
          App Name <span className={styles["i-red"]}>IREDAPP</span> &{" "}
          <span className={styles["i-red"]}>IREDAPP</span> are owned and
          operated by SHEMS CONSEILS which reserves the right to restrict access
          to the app change its pricing and modify the terms of use at any time
          without the consent of its user. It is the user’s responsibility to
          stay up to date with the terms.
        </div>
      </div>
    </div>
  );
}
