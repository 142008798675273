import React, { useState,useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { validateMac } from "../../../config/validation";
import REQUESTS from "../../../api/requests";

import MessageComponent from "../../../components/MessageComponent";
import ButtonComponent from "../../../components/ButtonComponent";

import styles from "../paymentPage.module.scss";

export default function PaymentWithCoupon() {
  let [searchParams] = useSearchParams();
  const [message, setMessage] = useState(null);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Coupon is required"),
    mac: Yup.string().required("Mac is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        mac: searchParams.get("activation_mac") ?? "",
        code: "",
      },
      onSubmit: (values) => {
        activationWithCoupon();
        setMessage(null);
      },
      validationSchema,
    });

  const activationWithCoupon = () => {
    REQUESTS.ACTIVATIO_WITH_COUPON(values).then((res) => {
      if (res.error) {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.success("Activation successful", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        resetForm();
      }
    });
  };

  useEffect(() => {
    let timeoutId;
    if (values.mac.trim()) {
      timeoutId = setTimeout(() => {
        validateMac(values.mac, setMessage);
      }, [500]);
    } else {
      setMessage(null);
    }

    return () => clearTimeout(timeoutId);
  }, [values.mac]);
  return (
    <div className={styles["activation"]}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <form className={styles["activation-form"]} onSubmit={handleSubmit}>
        <input
          placeholder="Enter your Coupon"
          className={styles["activation-form__inp"]}
          name="code"
          value={values.code}
          onChange={handleChange}
        />
        <MessageComponent
          message={errors.code && touched.code ? errors.code : null}
        />
        <input
          placeholder="Enter your MAC address"
          className={styles["activation-form__inp"]}
          name="mac"
          value={values.mac}
          onChange={handleChange}
        />
        <MessageComponent
          message={errors.mac && touched.mac ? errors.mac : message}
          msgtype={message === "Mac address is valid" ? "success" : "error"}
        />
        <ButtonComponent
          text="Activate"
          type="submit"
          style={{ marginTop: "24px", float: "right" }}
        />
      </form>
    </div>
  );
}
