import React from 'react'

import styles from "../../components/components.module.scss";
export default function TermsOfSales() {
  return (
    <div className={styles["terms-wrapper"]}>
      <div className={styles["terms-holder"]}>
        <h1 className={styles["privacy-title"]}>TERMS OF ONLINE SALE</h1>
        <div
          style={{
            fontSize: "20px",
          }}
        >
          We invite you to read these terms and conditions of online sales
          of SHEMS CONSEILS SARL site : https://iredtv.net The validation of
          your online transaction form is considered as an irrevocable
          acceptance of these terms. Definition : The “IREDAPP Website”
          defines the website{" "}
          <a href="#/" className={styles["terms-link"]}>
            {" "}
            https://iredtv.net{" "}
          </a>{" "}
          that is owned and operated by the company “SHEMS CONSEILS SARL”.
          <h3 className={styles["privacy-title"]}>1. Preamble</h3>
          <p>
            The following online sales terms govern all transactions
            established through the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website . Any
            transaction processed through this web site assumes the
            unconditional and irrevocable acceptance of these conditions by
            the customer.
          </p>
          <h3 className={styles["privacy-title"]}>2. Purpose</h3>
          <p>
            These terms are intended to define the rights and obligations of
            the parties in the context of the online sales done via the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website.
          </p>
          <h3 className={styles["privacy-title"]}>3. Sales</h3>
          <p>
            The customer connects to the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website and
            selects subscriptions that he wishes to buy. He then fills his
            identity information, accepts the terms and clicks on the “Pay”
            button. The customer is thus redirected to the Centre Monétique
            Interbancaire online secure payment platform where he completes
            his payment information and validates the payment. All of our
            Sales are final and no refunds are allowed even after the
            termination of the app and or the activation of the user’s
            account. The <span className={styles["i-red"]}>IREDAPP</span>{" "}
            Website reserves the right to terminate any user’s activation at
            any time and without providing any reason for the termination.
          </p>
          <h3 className={styles["privacy-title"]}>4. Payment Methods</h3>
          <p>
            To pay on Internet by credit card, the customer is called to enter
            his payment card credentials on the secure payment page. When the
            transaction is accepted by the bank, the settlement to debit his
            account is made in the working-day following the date of the
            transaction confirmation. Online payment with credit cards is
            secured by the Centre Monétique Interbancaire that provides a
            fully secure payment service. The customer guarantees the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website and the
            company SHEMS CONSEILS that he has enough funds required when
            using the payment method chosen by him during the payment
            validation. When paying by credit card, the conditions of this
            payment method that are contained in the agreements established
            between the customer and his issuer bank, and between the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website and his
            bank, are applied.
          </p>
          <h3 className={styles["privacy-title"]}>5. Data Privacy</h3>
          <p>
            The information requested from the customer during the online
            payment are required to process the online transaction. They are
            treated confidentially. The customer has the right to consult or
            rectify his personal data by sending a request electronic mail to
            the following email address{" "}
            <a
              href="mailto:support@iredtv.net"
              className={styles["terms-link"]}
            >
              support@iredtv.net
            </a>
          </p>
          <h3 className={styles["privacy-title"]}>6. Proof of payment</h3>
          <p>
            The data stored on the Centre Monétique Interbancaire online
            platform for the account of the{" "}
            <span className={styles["i-red"]}>IREDAPP</span> Website are
            considered as proof of payment for the online transaction made by
            the customer.
          </p>
        </div>
      </div>
    </div>
  );
}
