import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../api/requests";
import MessageComponent from "../../../components/MessageComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Modal from "../../../components/Modal";

import ConfirmCode from "./ConfirmCode";

import styles from "../devices.module.scss";

export default function AddDevice({ open, setOpen, getDevices }) {
  const [show, setShow] = useState(false);
  
  const validationSchema = Yup.object().shape({
    mac: Yup.string().required("Required"),
  });

  const { values, touched, errors, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues: {
        mac: "",
      },
      onSubmit: (values) => {
        addDevice();
      },
      validationSchema,
    });

  const addDevice = () => {
    REQUESTS.DEVICES.ADD(values).then((response) => {
      if (response.error) {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setShow(true);
      }
    });
  };

  useEffect(() => {
    if (!open) {
      resetForm();
      setShow(false);
    }
  }, [open]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal open={open} setOpen={setOpen}>
        <div className={styles["add-device-modal"]}>
          <p className={styles["add-device-modal__title"]}>Add new device</p>
          <form
            className={styles["add-device-modal__form"]}
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              placeholder="Enter device MAC address"
              name="mac"
              className={styles["add-device-inp"]}
              value={values.mac}
              onChange={handleChange}
            />
            <MessageComponent
              message={errors.mac && touched.mac ? errors.mac : null}
            />
            {!show && (
              <div className={styles["add-device-modal__btn-container"]}>
                <ButtonComponent
                  text="ADD DEVICE"
                  style={{ marginTop: 54 }}
                  type="submit"
                />
              </div>
            )}
          </form>
          {show && (
            <ConfirmCode
              mac={values.mac}
              setOpen={setOpen}
              getDevices={getDevices}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
