import { Routes, Route } from "react-router-dom";

import PATHS from "./config/paths";

import MainLayout from "./layout/MainLayout";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse";
import TermsOfSales from "./pages/termsOfSales/TermsOfSales";
import HomePage from "./pages/home/HomePage";
import DevicesPage from "./pages/devices/DevicesPage";
import PaymentError from "./pages/paymentError/PaymentError";
import PaymentSuccess from "./pages/paymentSuccess/PaymentSuccess";
import ForgotPassword from "./pages/resetPaasword/ForgotPassword";
import CurrentNews from "./pages/news/CurrentNews";
import NewsPage from "./pages/news/NewsPage";
import "./app.scss";
import PaymentPage from "./pages/payment/PaymentPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={PATHS.DEVICES} element={<DevicesPage />} />
        <Route path={PATHS.PAYMENT_ERROR} element={<PaymentError />} />
        <Route path={PATHS.PAYMENT_SUCCESS} element={<PaymentSuccess />} />

        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path={PATHS.PAYMENT} element={<PaymentPage />} />
          <Route path={PATHS.PRIVACY} element={<PrivacyPolicy />} />
          <Route path={PATHS.TERMS_OF_USE} element={<TermsOfUse />} />
          <Route path={PATHS.TERMS_OF_SALES} element={<TermsOfSales />} />
          <Route path={PATHS.RESET_PASSWORD} element={<ForgotPassword />} />
          <Route path="/news" element={<NewsPage />} /> 
           <Route path={"/news/:id"} element={<CurrentNews />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
