export const HOST = "https://api.iredtv.net";
export const API = `${HOST}/api/`;
export const API_V2 = `${HOST}/api/v2/`;

export const PAYPAL_CLIENT_ID =
  "ATRRzd1hBFllsOFbw5aa16xnl1LmRs2cHVxuqmqSzrsnvXVFxBn9SLpyVOmN80ePGUJAb6FlF5hGKj18";

//clinetId for test
// export const PAYPAL_CLIENT_ID =
// 'AXWLwWRAsCcjuoPSFKTgLTvHHYlFCwV3rPXp6AOMSnhJEdZ-RIdQPpNVa-Py74Y8lEFUaNfJmwl458Q2'
