import React from "react";
import { useNavigate } from "react-router-dom";

import IMAGES from "../../../../config/images";
import { parseDate } from "../../../../config/utils";
import styles from "../news.module.scss";

export default function NewsItem({ id, image, title, description, createdAt }) {
  const navigate = useNavigate();

  const onClickCard = () => {
    navigate(`/news/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles["news-card"]} onClick={onClickCard}>
      <img
        src={image}
        alt=""
        onError={(e) => (e.target.src = IMAGES.ERR_IMG)}
        className={styles["news-image"]}
      />
      <div className={styles["news-card__bottom"]}>
        <p className={styles["news-card__bottom__title"]}>{title}</p>
        <p className={styles["news-card__bottom__createdAt"]}>Added on: {parseDate(createdAt)} </p>
        <div className={styles["news-card__bottom__description"]}>
          {description && description.length > 100
            ? description.slice(0, 100) + "..."
            : description}
        </div>
      </div>
    </div>
  );
}
