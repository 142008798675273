import { useState } from "react";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import REQUESTS from "../../api/requests";

import MessageComponent from "../../components/MessageComponent";

import styles from "./styles.module.scss";
import ResetPassword from "./ResetPassword";

export default function ForgotPassword() {
  const [isShow, setIsShow] = useState(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: (values) => {
        forgontPassword();
      },
      validationSchema,
    });

  const forgontPassword = () => {
    REQUESTS.RESET_PASSWORD(values).then((data) => {
      if (!data.error) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    });
  };
  return (
    <div className={styles["reset-page-container"]}>
      <div className={styles["holder"]}>
        <div className={styles["reset-page-container__content"]}>
          {isShow ? (
            <ResetPassword email={values?.email} />
          ) : (
            <div className={styles["reset-page-container__content__form"]}>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  className={
                    styles["reset-page-container__content__form__input"]
                  }
                  value={values.email}
                  onChange={handleChange}
                />
                <MessageComponent
                  message={errors.email && touched.email ? errors.email : null}
                />
                <div className={styles["form-bottom"]}>
                    <Link to="/">Login to your account</Link>{" "}
                    <button type="submit" className={styles['btn']}>Get confirmation code</button>
                </div>
              </form>
            </div>
          )}
          <div className={styles["reset-page-container__content__description"]}>
            <h1 className={styles["title"]}>Reset your password</h1>
            <span className={styles["txt"]}>
              Enter your email address we will send you a new password.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
