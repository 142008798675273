import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import REQUESTS from "../../../api/requests";
import MessageComponent from "../../../components/MessageComponent";
import ButtonComponent from "../../../components/ButtonComponent";

import styles from "../devices.module.scss";


export default function ConfirmCode({ mac, setOpen, getDevices }) {
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Required"),
  });

  const { values, touched, errors, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues: {
        code: "",
      },
      onSubmit: (values) => {
        confirmCode();
      },
      validationSchema,
    });

  const confirmCode = () => {
    REQUESTS.CONFIRM_DEVICE_CODE({ ...values, mac }).then((res) => {
      if (res.error) {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setOpen(false);
        getDevices();
        resetForm();
      }
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter code"
          name="code"
          className={styles["add-device-inp"]}
          value={values.code}
          onChange={handleChange}
        />
        <MessageComponent message={errors.mac && touched.mac ? errors.mac : null}/>
        <div className={styles["add-device-modal__btn-container"]}>
          <ButtonComponent
            text="CONFIRM CODE"
            style={{ marginTop: 54 }}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
